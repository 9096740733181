import React, { useEffect, useState } from "react";
import { Input, Table, Row, Col } from "reactstrap";
import DatePicker from "reactstrap-date-picker/lib/DatePicker";
import moment from "moment";
import "moment-recur";
import { toast } from "react-toastify";

const RecurringAppointmentPanel = ({ appointments, setRecurredAppointmentArray }) => {

    const [endDate, setEndDate] = useState({
        value: null,
        formattedValue: null
    });

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [frequency, setFrequency] = useState('Weekly');
    const [recurringAppointment, setRecurringAppointment] = useState([]);

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const handleChange = (v, f) => {
        setEndDate(
            {
                value: v,
                formattedValue: f
            }
        )
    }

    useEffect(() => {

        if (endDate.value === "" || endDate.value === null) {
            return;
        }

        if (recurringAppointment[0] && recurringAppointment[0].appointmentDate > endDate.value) {
            toast("Invalid date selection", { className: 'toast-custom', type: toast.TYPE["ERROR"] })
            return;
        }

        if (recurringAppointment[0] && recurringAppointment[0].appointmentDate) {
            let duration = 7;
            switch (frequency) {
                case 'Weekly':
                    duration = 7;
                    break;
                case 'Daily':
                    duration = 1;
                    break;
            }

            let recurrence_dates = [];

            if (frequency === 'Monthly') {
                let done = false;
                let day = moment(recurringAppointment[0].appointmentDate, "YYYY-MM-DD").format('D');
                let currantAppointmentDate = moment(recurringAppointment[0].appointmentDate, "YYYY-MM-DD");
                recurrence_dates.push(currantAppointmentDate.format('YYYY-MM-DD'));

                while (!done) {
                    let nextYearMonth = currantAppointmentDate
                        .add(1, 'months')
                        .format('YYYY-MM');

                    let nextMonthDate = moment(nextYearMonth + '-' + day).format('YYYY-MM-DD');
                    let ed = moment(endDate.value).format('YYYY-MM-DD');

                    if (nextMonthDate <= ed) {
                        recurrence_dates.push(nextMonthDate);
                    }
                    else {
                        done = true;
                    }
                }

            }
            else {
                let recurrence = moment().recur(recurringAppointment[0].appointmentDate, endDate.value).every(duration).days();
                recurrence_dates = recurrence.all("YYYY-MM-DD");
            }

            console.log("recurrence_dates ", recurrence_dates)

            let newAppointments = recurrence_dates.map((el) => {
                let _rAppointment = {};
                const _rDate = el;

                _rAppointment = {
                    ...recurringAppointment[0],
                    appointmentDate: _rDate
                };
                return _rAppointment
            })

            setRecurringAppointment([...newAppointments]);
            setRecurredAppointmentArray([...newAppointments]);
        }
    }, [endDate.value, frequency]);

    useEffect(() => {
        setRecurringAppointment([appointments[appointments.length - 1]]);
    }, [appointments.length])

    return (
        <>
            <hr />
            <div style={{ padding: 2 }}>
                <Row>
                    <Col xs="4">
                        Frequency
                    </Col>
                    <Col>
                        <Input type="select" value={frequency} onChange={(e) => setFrequency(e.target.value)}>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                        </Input>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs="4">
                        End Date
                    </Col>
                    <Col>
                        <DatePicker id="recurance-end"
                            minDate={new Date().toISOString()}
                            value={endDate.value}
                            onChange={(v, f) => handleChange(v, f)}
                        />
                    </Col>
                </Row>
            </div>
            <Row>
                <Table bordered hover responsive className="mt-2" style={{ fontSize: "0.8rem" }}>
                    <tbody>
                        {recurringAppointment.map((ap, key) => <tr key={key}>
                            <td>{ap.appointmentDate}</td>
                            <td>{ap.serviceName}</td>
                            <td>{ap.startTime}</td>
                            <td>{ap.endTime}</td>
                        </tr>)}
                    </tbody>
                </Table>
            </Row>
        </>
    );
}

export default RecurringAppointmentPanel;