export const BUSINESS_TYPES = {
    SALON: {
        //Services view
        serviceLogo: 'serviceIcon',
        business_category_logo: 'staffIcon',
        services: 'Services',
        service: 'Service',
        example: "Haircut",
        groupExample: "Featured",
        leaves: "Leaves",

        id: 'SALON',
        title: 'Salon',

        //Staff view
        business_category_pluarl: 'Staff',
        business_category_singular: 'Staff',
        dropDownName: 'Select staff member',
        staff_member: true
    },
    SPORTS_CLUB: {
        serviceLogo: 'sportsIcon',
        business_category_logo: 'facilityIcon',
        services: 'Sports',
        service: 'Sport',
        example: 'Badminton',
        groupExample: "Featured",
        leaves: "Time off",

        id: 'SPORTS_CLUB',
        title: 'Sports Club',

        business_category_pluarl: 'Facilities',
        business_category_singular: 'Facility',
        dropDownName: 'Select facility',
        staff_member: false
    },
    LEARNERS: {
      serviceLogo: 'learnersServiceIcon',
      business_category_logo: 'instructorsIcon',
      services: 'Services',
      service: 'Service',
      example: "Learner's Permit",
      groupExample: "Featured",
      leaves: "Leaves",

      id: 'LEARNERS',
      title: 'Learners',

      business_category_pluarl: 'Instructors',
      business_category_singular: 'Instructor',
      dropDownName: 'Select Instructor',
      staff_member: true
    },
    CAR_RENTAL: {
      serviceLogo: 'sportsIcon',
      business_category_logo: 'facilityIcon',
      services: 'Services',
      service: 'Service',
      example: 'Limo Hires',
      groupExample: "Featured",
      leaves: "Leaves",

      id: 'CAR_RENTAL',
      title: 'Car Rentals',

      business_category_pluarl: 'Vehicles',
      business_category_singular: 'Vehicle',
      dropDownName: 'Select Vehicle',
      staff_member: true
    },
    SPA_WELLNESS: {
        //Services view
        serviceLogo: 'serviceIcon',
        business_category_logo: 'staffIcon',
        services: 'Services',
        service: 'Service',
        example: "Massage",
        groupExample: "Featured",
        leaves: "Leaves",

        id: 'SPA_WELLNESS',
        title: 'Spa and Wellness',

        //Staff view
        business_category_pluarl: 'Staff',
        business_category_singular: 'Staff',
        dropDownName: 'Select staff member',
        staff_member: true
    },
}

export const businessTypes = (typeId, key) => {

    let businessTypes = [{id: 1, name: 'SALON'}, {id: 2, name: 'LEARNERS'}, {id: 3, name: 'SPORTS_CLUB'},  {id: 4, name: 'SPA_WELLNESS'}]
    let value = BUSINESS_TYPES[`SALON`][key]
    businessTypes.map((item) => {
        if(item.id == typeId){
            if (BUSINESS_TYPES[item.name]) {
                value = BUSINESS_TYPES[item.name][key]
            }
        }
    })
    return value
}

const FIREBASE_CONFIGS = {
    dev: {
        apiKey: "AIzaSyB1dvK74L-8KdNF7LJP5eug7ZJI0PFasLw",
        authDomain: "bookezy-dev.firebaseapp.com",
        projectId: "bookezy-dev",
        storageBucket: "bookezy-dev.appspot.com",
        messagingSenderId: "738641927040",
        appId: "1:738641927040:web:b2eb0f72f6d346dd8c7f65",
        measurementId: "G-HX01HBB15J"
    },
    prod: {
        apiKey: "AIzaSyCGMTpgeuLKBkNQxqGkiCjSrfFpbR3pjcw",
        authDomain: "bookezy-lk.firebaseapp.com",
        projectId: "bookezy-lk",
        storageBucket: "bookezy-lk.appspot.com",
        messagingSenderId: "763338708377",
        appId: "1:763338708377:web:791982048e0d7f36572f08",
        measurementId: "G-9PEZSGHCHC"
    }
}

const ADMIN_DOMAINS = {
    dev: 'bookezy-dev-admin.web.app',
    staging: 'bookezy-staging-admin.web.app',
    prod: 'admin.bookezy.lk'
}

const CLIENT_DOMAINS = {
    dev: 'bookezy-dev.web.app',
    staging: 'bookezy-staging.web.app',
    prod: 'bookezy.lk'
}

const API_BASE_URLS = {
    dev: 'https://dev2.hatchyard.io',
    staging: 'https://dev2.hatchyard.io', // Change to staging baseURL
    prod: 'https://api.bookezy.lk'
}

const STRIPE_CONFIGS = {
    dev: {
        publishableKey: "pk_test_51GruO7IX4H3ank3puFEkRFUSdiBcTnRqum9yl3gxicF0HxNLJ9Ie1kntMVEBZSNoa60pFXXnWjmvYZDQeysg3xAn007GaGDdoV",
    },

    staging:  {
        publishableKey: "pk_test_51J55ZdI8wo0P6GFdRFaJpkrfBDi8ECaocxzjvZnaLO6G06YKqpxCWyrcugC4zTrGPL9XRhV3LfaesEy41TZtcV5N00PL9ICebW",
    },

    prod: {
        publishableKey: "pk_test_51GruO7IX4H3ank3puFEkRFUSdiBcTnRqum9yl3gxicF0HxNLJ9Ie1kntMVEBZSNoa60pFXXnWjmvYZDQeysg3xAn007GaGDdoV",
    }
}

export const configs = {
    country: 'LK',
    countryCode: '94',
    smsGateway: 'SL',
    currencyPrefix: 'Rs.',
    siteName: 'Bookezy',
    deployementName: 'Bookezy',
    businessIdExample: 'salon-abc',
    phoneNumberExample: 'eg: 0712345678 (10 digits format)',
    settingsPhoneNumberExample: 'eg: 94712345678 ',
    landPhoneRegex: new RegExp("^0[0-9]{9}?$"),
    isPricingActivated: true,
    hasBusinessTypes: true,
    phoneRegex: new RegExp("^07[0-9]{8}?$"),
    settingsPhoneRegex: new RegExp("^947[0-9]{8}?$"),

    adminDomain: ADMIN_DOMAINS[process.env.REACT_APP_STAGE],
    clientDomain: CLIENT_DOMAINS[process.env.REACT_APP_STAGE],
    firebase: FIREBASE_CONFIGS[process.env.REACT_APP_STAGE],
    baseURL: API_BASE_URLS[process.env.REACT_APP_STAGE],
    stripeConfigs: STRIPE_CONFIGS[process.env.REACT_APP_STAGE],
    algolia: {
        ALGOLIA_ID: "APYLMEEG22",
        ALGOLIA_SEARCH_KEY: "481478c01bff0273cf2b07070b17b148",
        ALGOLIA_INDEX_NAME: "customers"
    },
}
