import * as _ from 'lodash';
import {
    CLEAR_STORE,

    GET_SHOP_DATA_SUCCESS,

    GET_APPOINTMENTS,
    GET_APPOINTMENTS_SUCCESS,
    GET_APPOINTMENTS_ERROR,

    GET_CUSTOMERS,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_ERROR,
    GET_CUSTOMERS_FOR_PAGINATION,

    CREATE_EMPLOYEE_SUCCESS,

    CREATE_SERVICE_SUCCESS,

    UPDATE_EMPLOYEE_SUCCESS,
    DELETE_EMPLOYEE_SUCCESS,

    UPDATE_CUSTOMER_SUCCESS,

    UPDATE_SERVICE_SUCCESS,

    UPDATE_SHOP,
    UPDATE_SHOP_SUCCESS,
    UPDATE_SHOP_ERROR,

    ADD_APPOINTMENT_SUCCESS,
    UPDATE_APPOINTMENT_SUCCESS,

    ADD_CUSTOMER_SUCCESS,

    GET_UPCOMING_LEAVES,
    GET_UPCOMING_LEAVES_SUCCESS,
    GET_UPCOMING_LEAVES_ERROR,
    CHANGE_LEAVE_PERMISSION,

    GET_UPCOMING_HOLIDAYS,
    GET_UPCOMING_HOLIDAYS_SUCCESS,
    GET_UPCOMING_HOLIDAYS_ERROR,

    ADD_LEAVES_SUCCESS,
    ADD_HOLIDAYS_SUCCESS,

    DELETE_LEAVES_SUCCESS,
    DELETE_HOLIDAYS_SUCCESS,

    CANCEL_BOOKING_SUCCESS,
    GET_SERVICES_SUCCESS,
    GET_SERVICE_GROUPS_SUCCESS,
    GET_EMPLOYEES_SUCCESS,
    GET_BRANCHES_SUCCESS,

    GET_UPCOMING_TIME_OFFS,
    GET_UPCOMING_TIME_OFF_SUCCESS,
    GET_UPCOMING_TIME_OFF_ERROR,
    ADD_TIME_OFF_SUCCESS,
    UPDATE_TIME_OFF_SUCCESS,
    GET_DASHBOARD_DATA_ERROR,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_FACILITIES_SUCCESS,
    GET_FACILITIES,
    GET_FACILITIES_ERROR,
    CREATE_FACILITIES_SUCCESS,
    UPDATE_FACILITY_SUCCESS,
    GET_TOTAL_FACILITIES_SUCCESS,
    GET_TOTAL_EMPLOYEES_SUCCESS,
    GET_TOTAL_SERVICES_SUCCESS,
    GET_SERVICES,
    GET_SERVICES_ERROR,
    GET_EMPLOYEES,
    GET_EMPLOYEES_ERROR,
    UPDATE_SUB_STATUS,
    GET_PAYMENT_PACKAGES_SUCCESS,
    CREATE_BRANCH_SUCCESS,
    UPDATE_BRANCH_SUCCESS,
    SEARCH_BRANCHES_ERROR,
    SEARCH_BRANCHES,
    SEARCH_BRANCHES_SUCCESS,
    SELECT_BRANCH,
    GET_DASHBOARD_DATA,
    GET_SERVICE_GROUPS_ERROR,
    GET_SERVICE_GROUPS,
} from "../actions/types";

const INITIAL_STATE = {
    employeeId: null,
    customers: [],
    appointments: [],
    leaves: [],
    holidays: [],
    timeOffs: [],

    openingTime: "Sun nov 14 2021 08:00:00 GMT+1000",  // Tempararory (Change to be assigned when signing in)
    closingTime: "Sun nov 14 2021 20:00:00 GMT+1000",

    isFetchingCustomers: false,
    isUpdatingShop: false,
    isFetchingUpcomingLeaves: false,
    isFetchingUpcomingTimeOffs: false,
    isFetchingUpcomingHolidays: false,
    isFetchingFacilities: false,
    isFetchingServices: false,
    isFetchingServiceGroups: false,
    isFetchingEmployees: false,
    isFetchingBranches: false,
    isFetchingDashboardData: false,

    businessAddress: '',
    mobileNo: '',
    businessId: null,
    businessName: '',
    businessNo: '',
    businessTypeId: null,
    ownerEmail: '',
    ownerName: '',
    shopId: null,
    shopTimeArr: [],
    status: null,
    userId: null,
    imageUrl: null,
    logoUrl: null,
    services: [],
    serviceGroups: [],
    employees: [],
    branches: [],
    branchesForSearch: [],
    dashboardData: [],
    timeZone: '',
    facilities: [],
    businessMode: '',
    paymentPackageList: [],
    selectedBranch: {},

    servicesPageCount: 1,
    employeesPageCount: 1,
    customersPageCount: 1,
    facilitiesPageCount: 1,
    branchesPageCount: 1,
    total: 0,

    totalServices: [],
    totalEmployees: [],
    totalFacilities: [],
    isFetchingTotalEmployees: true,

    isSubscribed: false,
    stripeId: '',
    subscription: null,

    appointmentDateRange: "",

    advertisements: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_STORE:
            return {
                ...INITIAL_STATE
            }
        case GET_SHOP_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload.shopData
            }
        case GET_TOTAL_SERVICES_SUCCESS:
            return {
                ...state,
                totalServices: action.payload.totalServices.pageList
            }
        case GET_TOTAL_EMPLOYEES_SUCCESS:
            return {
                ...state,
                totalEmployees: action.payload.totalEmployees.pageList[0].employeeDTOList,
                isFetchingTotalEmployees: false
            }
        case GET_TOTAL_FACILITIES_SUCCESS:
            return {
                ...state,
                totalFacilities: action.payload.totalFacilities.pageList,
            }
        case GET_SERVICES:
            return {
                ...state,
                isFetchingServices: true
            }
        case GET_SERVICES_SUCCESS:
            return {
                ...state,
                services: action.payload.services.pageList,
                servicesPageCount: action.payload.services.numberOfPages,
                total: action.payload.services.total,
                isFetchingServices: false
            }
        case GET_SERVICES_ERROR:
            return {
                ...state,
                isFetchingServices: false
            }
        case GET_SERVICE_GROUPS: // TODO: handle loading & errors on UI
            return {
                ...state,
                isFetchingServiceGroups: true
            }
        case GET_SERVICE_GROUPS_SUCCESS:
            return {
                ...state,
                serviceGroups: action.payload.groups.serviceGroupList,
                isFetchingServiceGroups: false
            }
        case GET_SERVICE_GROUPS_ERROR:
            return {
                ...state,
                isFetchingServiceGroups: false
            }
        case GET_EMPLOYEES:
            return {
                ...state,
                isFetchingEmployees: true
            }
        case GET_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: action.payload.employees.pageList[0].employeeDTOList,
                employeesPageCount: action.payload.employees.numberOfPages,
                total: action.payload.employees.total,
                isFetchingEmployees: false
            }
        case GET_EMPLOYEES_ERROR:
            return {
                ...state,
                isFetchingEmployees: false
            }
        case GET_FACILITIES:
            return {
                ...state,
                isFetchingFacilities: true
            }
        case GET_FACILITIES_SUCCESS:
            return {
                ...state,
                facilities: action.payload.facilities.pageList,
                facilitiesPageCount: action.payload.facilities.numberOfPages,
                total: action.payload.facilities.total,
                isFetchingFacilities: false
            }
        case GET_FACILITIES_ERROR:
            return {
                ...state,
                isFetchingFacilities: false
            }
        case UPDATE_SHOP:
            return {
                ...state,
                isUpdatingShop: true
            }
        case UPDATE_SHOP_SUCCESS:
            return {
                ...state,
                ...action.payload.updatedData,
                isUpdatingShop: false,
            }
        case UPDATE_SHOP_ERROR:
            return {
                ...state,
                isUpdatingShop: false
            }
        case GET_APPOINTMENTS:
            return {
                ...state,
                isFetchingAppointments: true

            }
        case GET_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointments: action.payload.appointments.pageList,
                // numberOfPages: action.payload.appointments.numberOfPages,
                // total: action.payload.appointments.total,
                isFetchingAppointments: false
            }
        case GET_APPOINTMENTS_ERROR:
            return {
                ...state,
                isFetchingAppointments: false
            }
        case GET_CUSTOMERS:
            return {
                ...state,
                isFetchingCustomers: true
            }
        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: action.payload.customers.pageList,
                customersPageCount: action.payload.customers.numberOfPages,
                total: action.payload.customers.total,
                isFetchingCustomers: false
            }
        case GET_CUSTOMERS_FOR_PAGINATION:
            return {
                ...state,
                customers: action.payload.customers.pageList,
                customersPageCount: action.payload.customers.numberOfPages,
                total: action.payload.customers.total,
                isFetchingCustomers: false
            }
        case GET_CUSTOMERS_ERROR:
            return {
                ...state,
                isFetchingCustomers: false
            }
        case CREATE_EMPLOYEE_SUCCESS:
            let empClone = [...state.employees]
            empClone = [...empClone, action.payload.data]
            let totalEmpClone = [...state.totalEmployees]
            totalEmpClone = [...totalEmpClone, action.payload.data]
            return {
                ...state,
                employees: empClone,
                totalEmployees: totalEmpClone
            }
        case CREATE_FACILITIES_SUCCESS:
            let facClone = [...state.facilities]
            facClone = [...facClone, action.payload.data]
            let totalFacClone = [...state.totalFacilities]
            totalFacClone = [...totalFacClone, action.payload.data]
            return {
                ...state,
                facilities: facClone,
                totalFacilities: totalFacClone
            }
        case CREATE_SERVICE_SUCCESS:
            let services = [...state.services]
            services = [...services, action.payload.data]
            let totalServices = [...state.totalServices]
            totalServices = [...totalServices, action.payload.data]

            const serviceGroups = [...state.serviceGroups];

            const index = serviceGroups.findIndex(i => i.groupId === action.payload.data?.serviceGroup?.groupId);
            if (index < 0) {
                serviceGroups.push(action.payload.data.serviceGroup);
            }

            return {
                ...state,
                services: services,
                totalServices: totalServices,
                serviceGroups,
            }
        case UPDATE_EMPLOYEE_SUCCESS:
            let id = action.payload.updatedEmployee.employeeId
            let empClone2 = state.employees
            let objIndex = empClone2.findIndex((obj => obj.employeeId === id))
            empClone2[objIndex] = { ...empClone2[objIndex], ...action.payload.updatedEmployee }

            let totalEmpClone2 = state.totalEmployees
            let totalEmpIndex = totalEmpClone2.findIndex((obj => obj.employeeId === id))
            totalEmpClone2[totalEmpIndex] = { ...totalEmpClone2[totalEmpIndex], ...action.payload.updatedEmployee }

            return {
                ...state,
                employees: empClone2,
                totalEmployees: totalEmpClone2
            }
        case DELETE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employeeId: action.payload.employeeId
            }
        case UPDATE_FACILITY_SUCCESS:
            let fscilityId = action.payload.updatedFacility.facilityId
            let facClone2 = state.facilities
            let facObjIndex = facClone2.findIndex((obj => obj.facilityId === fscilityId))
            facClone2[facObjIndex] = { ...facClone2[facObjIndex], ...action.payload.updatedFacility }

            let totalFacClone2 = state.totalFacilities
            let totalFacObjIndex = totalFacClone2.findIndex((obj => obj.facilityId === fscilityId))
            totalFacClone2[totalFacObjIndex] = { ...totalFacClone2[totalFacObjIndex], ...action.payload.updatedFacility }

            return {
                ...state,
                facilities: facClone2,
                totalFacilities: totalFacClone2
            }
        case UPDATE_CUSTOMER_SUCCESS:
            let customersClone = [...state.customers]
            let customerIndex = _.findIndex(customersClone, (o) => { return o.customerId === action.payload.customerId });
            customersClone[customerIndex] = { ...customersClone[customerIndex], ...action.payload.updateObject }
            return {
                ...state,
                customers: customersClone
            }
        case UPDATE_SERVICE_SUCCESS:
            let servicesClone = state.services
            servicesClone = servicesClone.map(item => item.serviceId === action.payload.data.serviceId ? action.payload.data : item)

            let totServicesClone = state.totalServices
            totServicesClone = totServicesClone.map(item => item.serviceId === action.payload.data.serviceId ? action.payload.data : item)

            const serviceGroupsClone = [...state.serviceGroups];
            if (!serviceGroupsClone.includes(action.payload.response.serviceGroup)) {
                serviceGroupsClone.push(action.payload.response.serviceGroup);
            }

            return {
                ...state,
                services: servicesClone,
                totalServices: totServicesClone,
                serviceGroups: serviceGroupsClone,
            }
        case ADD_APPOINTMENT_SUCCESS:
            return {
                ...state,
                appointments: [...state.appointments, action.payload.appointment]
            }
        case UPDATE_APPOINTMENT_SUCCESS:
            let appointmentsClone = { ...state.appointments }
            let newAppointmentClone
            newAppointmentClone = _.map(appointmentsClone, (appointment) => {
                if (appointment.id === action.payload.id) {
                    appointment = { ...appointment, ...action.payload.updatedData }
                }
                return appointment
            });
            return {
                ...state,
                appointments: newAppointmentClone
            }
        case ADD_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: [action.payload.customer, ...state.customers,]
            }
        case GET_UPCOMING_LEAVES:
            return {
                ...state,
                isFetchingUpcomingLeaves: true
            }
        case GET_UPCOMING_LEAVES_SUCCESS:
            return {
                ...state,
                leaves: action.payload.leaves,
                isFetchingUpcomingLeaves: false,
            }
        case GET_UPCOMING_LEAVES_ERROR:
            return {
                ...state,
                isFetchingUpcomingLeaves: false
            }
        case ADD_LEAVES_SUCCESS:
            let leaves = [...state.leaves]
            leaves = [...leaves, action.payload.leaves]
            return {
                ...state,
                leaves: leaves
            }
        case CHANGE_LEAVE_PERMISSION:
            return {
                ...state,
                leaves: _.filter(state.leaves, (obj) => {
                    if (obj.leaveId === action.payload.permit.leaveId) {
                        obj.permission = action.payload.permit.permissionType
                    } return obj
                })
            }

        case GET_UPCOMING_TIME_OFFS:
            return {
                ...state,
                isFetchingUpcomingTimeOffs: true
            }
        case GET_UPCOMING_TIME_OFF_SUCCESS:
            return {
                ...state,
                timeOffs: action.payload.timeOffs,
                isFetchingUpcomingTimeOffs: false,
            }
        case GET_UPCOMING_TIME_OFF_ERROR:
            return {
                ...state,
                isFetchingUpcomingTimeOffs: false
            }
        case ADD_TIME_OFF_SUCCESS:
            let timeOffs = [...state.timeOffs]
            timeOffs = [...timeOffs, action.payload.facilityTimeOffs]
            return {
                ...state,
                timeOffs: timeOffs
            }

        case ADD_HOLIDAYS_SUCCESS:
            let holidayss = [...state.holidays]
            holidayss = [...holidayss, action.payload.holidays]
            return {
                ...state,
                holidays: holidayss
            }

        case GET_UPCOMING_HOLIDAYS:
            return {
                ...state,
                isFetchingUpcomingHolidays: true
            }

        case GET_UPCOMING_HOLIDAYS_SUCCESS:
            return {
                ...state,
                holidays: action.payload.holidays,
                isFetchingUpcomingHolidays: false,
            }
        case GET_UPCOMING_HOLIDAYS_ERROR:
            return {
                ...state,
                isFetchingUpcomingHolidays: false
            }

        case DELETE_HOLIDAYS_SUCCESS:
            let holidays = state.holidays
            let result = _.filter(holidays, (o) => {
                return !_.includes(action.payload.holidayIds, o.shopHolidayId)
            })

            return {
                ...state,
                holidays: result
            }
        case GET_BRANCHES_SUCCESS:
            return {
                ...state,
                branches: action.payload.branches
            }

        case DELETE_LEAVES_SUCCESS:
            let empLeaves = state.leaves
            let results = _.filter(empLeaves, (o) => {
                return !_.includes(action.payload.leaveIds, o.leaveId)
            })
            return {
                ...state,
                leaves: results
            }
        case UPDATE_TIME_OFF_SUCCESS:
            let facilityTimeOffs = state.timeOffs
            let filteredResults = _.filter(facilityTimeOffs, (o) => {
                return !_.includes(action.payload.timeOffIds, o.facilityTimeOffId)
            })
            return {
                ...state,
                timeOffs: filteredResults
            }
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                isFetchingDashboardData: true
            }
        case GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                isFetchingDashboardData: false,
                dashboardData: action.payload.dashboardData
            }
        case GET_DASHBOARD_DATA_ERROR:
            return {
                ...state,
                isFetchingDashboardData: false
            }
        case CANCEL_BOOKING_SUCCESS:
            return {
                ...state,
                appointments: _.filter(state.appointments, (obj) => { if (obj.id === action.payload.appointmentId.apointmentId) { obj.status = "Inactive" } return obj })
            }
        case UPDATE_SUB_STATUS:
            return {
                ...state,
                isSubscribed: true
            }
        case GET_PAYMENT_PACKAGES_SUCCESS:
            return {
                ...state,
                paymentPackageList: action.payload.paymentPackageList
            }
        case CREATE_BRANCH_SUCCESS:
            return {
                ...state,
                branches: [...state.branches, action.payload.newBranch],
                branchesForSearch: [...state.branchesForSearch, action.payload.newBranch]
            }
        case UPDATE_BRANCH_SUCCESS:
            let branchesClone = [...state.branches]
            let branchIndex = _.findIndex(branchesClone, (o) => { return o.branchId === action.payload.updatedBranch.branchId });
            branchesClone[branchIndex] = { ...branchesClone[branchIndex], ...action.payload.updatedBranch }

            let branchesForSearchClone = [...state.branchesForSearch]
            let searchListIndex = _.findIndex(branchesForSearchClone, (o) => { return o.branchId === action.payload.updatedBranch.branchId });
            branchesForSearchClone[searchListIndex] = { ...branchesForSearchClone[searchListIndex], ...action.payload.updatedBranch }

            let updatedSelectedBranch = state.selectedBranch
            if (state.selectedBranch.branchId === action.payload.updatedBranch.branchId) {
                updatedSelectedBranch = { ...updatedSelectedBranch, ...action.payload.updatedBranch }
            }

            return {
                ...state,
                branches: branchesClone,
                branchesForSearch: branchesForSearchClone,
                selectedBranch: updatedSelectedBranch
            }
        case SEARCH_BRANCHES:
            return {
                ...state,
                isFetchingBranches: true
            }
        case SEARCH_BRANCHES_SUCCESS:
            return {
                ...state,
                branchesForSearch: action.payload.branches.pageList,
                branchesPageCount: action.payload.branches.numberOfPages,
                total: action.payload.branches.total,
                isFetchingBranches: false
            }
        case SEARCH_BRANCHES_ERROR:
            return {
                ...state,
                isFetchingBranches: false
            }

        case SELECT_BRANCH:
            return {
                ...state,
                isFetchingDashboardData: true,
                selectedBranch: action.payload.branch,
            }
        default:
            return state
    }
}
