import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Loadable from 'react-loadable'
import { connect } from "react-redux";
import { logout } from '../utils/auth'
import * as _ from 'lodash';
import jwt_decode from 'jwt-decode'

// Add the Firebase products that you want to use
import "firebase/auth";
import {
    Spinner, Row, Col
} from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { initializeNotification, signOut, changeAuthState, getShopData, getServices, getEmployees, getBranches, getAppointments, getTotalServices, getTotalEmployees, getTotalFacilities, getFacilities, selectBranch, searchBranches } from './../actions'
import { BRANCHES_PAGE_SIZE } from '../utils/constants'
import SideBar from "../components/SideBar"
import NotFoundView from './NotFoundView';
import { fetchBranches } from '../api/api'

function Loading(props) {
    if (props.pastDelay) {
        return <div />;
    } else {
        return null;
    }
}

function PrivateRoute({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
        />
    )
}

function PublicRoute({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => authed === false
                ? <Component {...props} />
                : <Redirect to='/' />}
        />
    )
}


const LoginView = Loadable({
    loader: () => import('../containers/LoginViewNew/LoginView'),
    loading: Loading,
    delay: 300
})

const AppoinmentsView = Loadable({
    loader: () => import('../containers/AppoinmentsView/AppoinmentsView'),
    loading: Loading,
    delay: 300
})

const EmployeesView = Loadable({
    loader: () => import('../containers/EmployeesView/EmployeesView'),
    loading: Loading,
    delay: 300
})

const ServicesView = Loadable({
    loader: () => import('../containers/ServicesView/ServicesView'),
    loading: Loading,
    delay: 300
})
const CustomersView = Loadable({
    loader: () => import('../containers/CustomersView/CustomersView'),
    loading: Loading,
    delay: 300
})

const SignUpView = Loadable({
    loader: () => import('../containers/SignUpView/SignUpView'),
    loading: Loading,
    delay: 300
})

const SubscriptionsView = Loadable({
    loader: () => import('../containers/SubscriptionsView/SubscriptionsView'),
    loading: Loading,
    delay: 300
})

const SubscriptionsSuccessView = Loadable({
    loader: () => import('../containers/SubscriptionsView/SubscriptionsSuccessView'),
    loading: Loading,
    delay: 300
})

const CovidCustomersView = Loadable({
    loader: () => import('../containers/CovidCustomersView/CovidCustomersView'),
    loading: Loading,
    delay: 300
})

const SettingsView = Loadable({
    loader: () => import('../containers/SettingsView/SettingsView'),
    loading: Loading,
    delay: 300
})

const AdminView = Loadable({
    loader: () => import('../containers/AdminView/AdminView'),
    loading: Loading,
    delay: 300
})

const BranchesView = Loadable({
    loader: () => import('../containers/BranchesView/BranchesView'),
    loading: Loading,
    delay: 300
})

const AnalyticsView = Loadable({
    loader: () => import('../containers/AnalyticsView/AnalyticsView'),
    loading: Loading,
    delay: 300
})

const ForgotPWView = Loadable({
    loader: () => import('../containers/ForgotPWView/ForgotPWView'),
    loading: Loading,
    delay: 300
})

const ResetPWView = Loadable({
    loader: () => import('../containers/ResetPWView/ResetPWView'),
    loading: Loading,
    delay: 300
})

const ChangeEmployeePWView = Loadable({
    loader: () => import('../containers/ChangeEmployeePWView/ChangeEmployeePWView'),
    loading: Loading,
    delay: 300
})

const SupportView = Loadable({
    loader: () => import('../containers/SupportView/SupportView'),
    loading: Loading,
    delay: 300
})

const DashBoard = Loadable({
    loader: () => import('../containers/DashBoardView/DashBoardView'),
    loading: Loading,
    delay: 300
})

const FacilitiesView = Loadable({
    loader: () => import('../containers/FacilitiesView/FacilitiesView'),
    loading: Loading,
    delay: 300
})

const FacilityAppoinmentsView = Loadable({
    loader: () => import('../containers/FacilityAppointmentsView/FacilityAppointmentsView'),
    loading: Loading,
    delay: 300
})

const PrivacyPolicyView = Loadable({
    loader: () => import('../containers/PrivacyPolicyView/PrivacyPolicyView'),
    loading: Loading,
    delay: 300
})

const TermsView = Loadable({
    loader: () => import('../containers/TermsView/TermsView'),
    loading: Loading,
    delay: 300
})

class NavRouter extends Component {


    state = {
        loggedIn: false,
        loading: true
    }

    componentDidMount() {
        let self = this
        
        const isValidToken = (loginToken) => {
            if(!loginToken) {
                return false;
            }

            const decoded = jwt_decode(loginToken);
            const currentTime = Date.now() / 1000;

            return decoded.exp > currentTime;
        }

        const accessToken = window.localStorage.getItem('loginToken');
        console.log("TOKEN", accessToken)

        if (accessToken && isValidToken(accessToken) && !_.isEmpty(this.props.selectedBranch)) {
            console.log("VALID TOKEN")

            fetchBranches(window.localStorage.getItem('shopId')).then(res => {
                this.props.getBranches(res)

                if(this.props.branches) {
                    try {
                        self.props.changeAuthState(true)
                        self.props.searchBranches(self.props.selectedBranch.shopId, "", "", 0, BRANCHES_PAGE_SIZE)
                        self.props.getShopData(self.props.selectedBranch.shopId)
                        self.props.getServices(self.props.selectedBranch.branchId, "", 0)
                        self.props.getTotalServices(self.props.selectedBranch.branchId)

                        if(self.props.businessMode === 'EmployeeMode'){
                            self.props.getEmployees(self.props.selectedBranch.branchId, "", "", "", "", 0)
                            self.props.getTotalEmployees(self.props.selectedBranch.branchId)
                        }
                        else {
                            self.props.getFacilities(self.props.selectedBranch.branchId, "", 0)
                            self.props.getTotalFacilities(self.props.selectedBranch.branchId)
                        }
                        self.props.getAppointments(self.props.selectedBranch.branchId)
                        self.setState({ loading: false, loggedIn: true })

                    } catch (error) {
                        console.log(error)
                        self.setState({ loading: true, loggedIn: false })
                    }
                } else {
                    self.setState({ loading: true, loggedIn: false })
                }

            }).catch(err => {
                console.log(err)
                self.setState({ loading: true, loggedIn: false })
            })

        } else {
            self.setState({ loading: false })
            this.props.changeAuthState(false)
        }
    }

    isFirstTime = (createdDateString) => {
        try {
            let timeDifference = (new Date()).getTime() - (new Date(createdDateString)).getTime()
            if (timeDifference < 5000) {
                console.log('IS FIRST TIME')
                return true
            } else {
                console.log('NOT FIRST TIME')
                return false
            }
        } catch (err) {
            return false
        }
    }

    render() {

        const { isLoggedIn } = this.props

        if (this.state.loading) {
            return (
                <Row className="text-center align-items-center vh-100 m-0 p-0">
                    <Col className="align-self-center m-0 p-0">
                        <Spinner color="primary" />
                    </Col>
                </Row>
            )
        } else {
            return (
                <BrowserRouter>
                    <ToastContainer hideProgressBar draggable={false} style={{ textAlign: 'center' }} />
                    <Switch>
                        <PublicRoute exact path={'/login'} component={LoginView} authed={isLoggedIn} />
                        <PublicRoute exact path={'/signup'} component={SignUpView} authed={isLoggedIn} />
                        <PublicRoute exact path={'/reset'} component={ForgotPWView} authed={isLoggedIn} />
                        <PublicRoute exact path={'/password/reset'} component={ResetPWView} authed={isLoggedIn} />
                        <PublicRoute exact path={'/password/change'} component={ChangeEmployeePWView} authed={isLoggedIn} />
                        <PublicRoute exact path={'/privacyPolicy'} component={PrivacyPolicyView} authed={isLoggedIn}/>
                        <PublicRoute exact path={'/termsOfUse'} component={TermsView} authed={isLoggedIn}/>
                        <PrivateRoute exact path={'/subscriptions'} component={SubscriptionsView} authed={isLoggedIn} />
                        <PrivateRoute exact path={'/subscriptions/success'} component={SubscriptionsSuccessView} authed={isLoggedIn} />
                        <Route exact path={'/'} render={() => <Redirect to={{ pathname: '/dashboard' }} />} />
                        
                        <SideBar main={
                            <Switch>
                                <PrivateRoute exact path={'/appointments'} component={AppoinmentsView} authed={isLoggedIn} />
                                <PrivateRoute exact path={'/staff'} component={EmployeesView} authed={isLoggedIn} />
                                <PrivateRoute exact path={'/services'} component={ServicesView} authed={isLoggedIn} />
                                <PrivateRoute exact path={'/customers'} component={CustomersView} authed={isLoggedIn} />
                                <PrivateRoute exact path={'/covid'} component={CovidCustomersView} authed={isLoggedIn} />
                                <PrivateRoute exact path={'/analytics'} component={AnalyticsView} authed={isLoggedIn} />
                                <PrivateRoute exact path={'/settings'} component={SettingsView} authed={isLoggedIn} />
                                <PrivateRoute exact path={'/admin'} component={AdminView} authed={isLoggedIn} />
                                <PrivateRoute exact path={'/admin/branches'} component={BranchesView} authed={isLoggedIn && localStorage.getItem('role') === 'SuperAdmin'} />
                                {/* <PrivateRoute exact path={'/support'} component={SupportView} authed={isLoggedIn} /> */}
                                <PrivateRoute exact path={'/dashboard'} component={DashBoard} authed={isLoggedIn} />
                                <PrivateRoute exact path={'/facilities'} component={FacilitiesView} authed={isLoggedIn} />
                                <PrivateRoute exact path={'/facilityAppointments'} component={FacilityAppoinmentsView} authed={isLoggedIn} />
                                <Route render={() => <NotFoundView />} />
                            </Switch>
                        } />

                    </Switch>

                </BrowserRouter >
            )
        }

    }
}

const mapStateToProps = (state) => {
    return {
        login: state.user.login,
        isLoggedIn: state.user.isLoggedIn,
        branches: state.shop.branches,
        businessMode: state.shop.businessMode,
        selectedBranch: state.shop.selectedBranch
    }
}

export default connect(mapStateToProps, {initializeNotification, signOut, changeAuthState, getShopData, getServices, getEmployees, getBranches, getAppointments, getTotalServices, getTotalEmployees, getTotalFacilities, getFacilities, selectBranch, searchBranches})(NavRouter);
