import React, { Component } from 'react'
import {
    Button, Spinner,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input, Col, Row,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormFeedback, FormText
} from 'reactstrap';
import * as _ from 'lodash';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment'

import { notify } from './../../utils/common'
import { DEFAULT_PROFILE_IMAGE } from './../../utils/constants'
import { ReactComponent as PlusIcon } from "../../assets/images/plus.svg"
import { businessTypes } from "../../utils/configs"
import { configs } from '../../utils/configs'
import { checkPhoneNumber } from '../../utils/common'
import './addAppointmentModal.css'
import MultipleAppointmentGrid from '../AppointmetTypes/MultipleAppointmentGrid';
import RecurringAppointmentPanel from '../AppointmetTypes/RecurringAppointmentPanel';
import { toast } from "react-toastify";
import { getAppointments, confirmAppointment, getCustomers } from '../../actions';
import { connect } from "react-redux";
import confirm from "reactstrap-confirm";

import Images from "../../assets/images"
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import WarningModal from '../WarningModal/WarningModal';
import { toggleAddAppointmentModal, configAddAppointmentModal } from '../../actions/BaseComponentsActions';
import { fetchCustomers } from '../../api/api';

const enumAppointment = {
    SINGLE: "SINGLE",
    MULTIPLE: "MULTIPLE",
    RECURRING: "RECURRING"
}

const TIME_SLOT_SIZE = 15; // minutes
const WEEK_MAP = { Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6 }

const DEFAULT_MODAL_STATE = {
    isModalOpen: false,
    isAddingAppointment: false,
    isError: false,
    errorMessage: '',
    isValid: true,
    modalType: 'NEW',
    isWarningModalOpen: false,

    // Customer
    gender: '',
    firstName: '',
    lastName: '',
    mobileNo: '',
    email: '',
    emailError: '',
    firstNameError: '',
    lastNameError: '',
    isSearching: false,

    //Service - id, name, serviceTime
    service: {},

    // Employee - id, name, image
    employee: {},
    employees: [],  // Employees of the selected service

    appointmentDate: null,
    startTime: null,
    endTime: null,

    timeSlots: [],
    selectedTimeSlot: null,
    disabledCalendarDates: null,

    specialNotes: '',
    amount: null,
    halfCourtName: 'FULL',
    isPartial: false,

    appointmentsArray: [],
    appointmentType: enumAppointment.MULTIPLE,
    recurringMoment: false
}

let updatedData = {}

export class AddAppointmentModal extends Component {

    constructor(props) {
        super(props)

        this.state = {...DEFAULT_MODAL_STATE};

        if (props.edit) {
            this.state.appointmentType = enumAppointment.SINGLE;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isHandleByRedux && !prevProps.addAppointmentModalConfig.isOpen && this.props.addAppointmentModalConfig.isOpen) {
            const config = {};
            _.forEach(this.props.addAppointmentModalConfig, (val, key) => {
               if (key !== 'isOpen') {
                   config[key] = val
               }
            });

            if (Object.keys(config).length > 0) {
                this.setState(config)
            }
        }
    }

    // Construct time slotesF
    buildtimeSlots = async (interval, employeeId, selectedDate, bookedSlots, ot, ct) => {
        let offSet = moment().tz(this.props.timeZone).format('Z');
        // convert to moment objects
        let openTime = moment(ot)
        let closeTime = moment(ct)
        let lastTime = closeTime.subtract(interval, 'minutes')

        let timeSlots = []
        let timeOff = []    // holds holidays/leaves time slots

        let aTime = openTime

        let leaves = []
        let holidays = []

        // Get leaves (if any) of the selected date
        _.map(this.props.leaves, (obj) => {

            let dateA = moment(obj.leaveDate).format('YYYY-MM-DD')
            let dateB = moment(selectedDate).format('YYYY-MM-DD')

            if (employeeId === obj.employeeId && dateA === dateB) {
                if (!_.isEmpty(obj.startTime)) {
                    leaves.push(obj)
                }
            }
        })
        // gets shop holidays of the selected date
        _.map(this.props.holidays, (obj) => {

            let dateA = moment(obj.holiday).format('YYYY-MM-DD')
            let dateB = moment(selectedDate).format('YYYY-MM-DD')

            if (dateA === dateB) {
                if (!_.isEmpty(obj.startTime)) {
                    holidays.push(obj)
                }
            }
        })

        while (aTime.isSameOrBefore(lastTime)) {

            let TS = aTime.format('h:mm a')
            let TE = moment(aTime).add(interval, 'minutes').format('h:mm a')

            const checkBookedSlots = () => {
                if (_.isEmpty(bookedSlots)) {
                    timeSlots.push(aTime.format('h:mm a'))
                } else {

                    let isClashing = false

                    _.forEach(bookedSlots, (obj) => {
                        let AS = moment(obj.startTime).format('h:mm a')
                        let AE = moment(obj.endTime).format('h:mm a')

                        // Check if timeslots clash with previous appointments
                        isClashing = this.timeSlotsClash(TS, TE, AS, AE)

                        if (isClashing) {
                            // Stop loop
                            return false
                        }
                    })

                    if (!isClashing) {
                        timeSlots.push(aTime.format('h:mm a'))
                    } else {
                        console.log("Clash (booked)", aTime.format('h:mm a'))
                    }
                }
            }
            // filter duplicated time slots caused by map in leaves and holidays
            const filterTimeSlots = () => {
                timeSlots = timeSlots.filter(val => !timeOff.includes(val));
                const uniqueSet = new Set(timeSlots)
                const backToArray = [...uniqueSet]
                timeSlots = backToArray
            }

            if (this.isTimeSlotExpired(selectedDate, TS)) {
                console.log('Timeslot Expired', TS)
            } else {
                if (!_.isEmpty(leaves) && !_.isEmpty(holidays)) {

                    _.map(leaves, (leave) => {
                        let LS = moment(`Sun nov 14 2021 ${leave.startTime}:00 GMT${offSet}`).format('h:mm a')
                        let LE = moment(`Sun nov 14 2021 ${leave.endTime}:00 GMT${offSet}`).format('h:mm a')

                        _.map(holidays, (holiday) => {
                            let HS = moment(`Sun nov 14 2021 ${holiday.startTime}:00 GMT${offSet}`).format('h:mm a')
                            let HE = moment(`Sun nov 14 2021 ${holiday.endTime}:00 GMT${offSet}`).format('h:mm a')

                            // Check if timeslots clash with employee time off
                            if (!this.timeSlotsClash(TS, TE, LS, LE) && !this.timeSlotsClash(TS, TE, HS, HE)) {
                                checkBookedSlots()

                            } else {
                                console.log("Clash (leave & holiday)", aTime.format('h:mm a'))
                                timeOff.push(aTime.format('h:mm a'))
                            }
                        })
                    })
                    filterTimeSlots()

                } else if (!_.isEmpty(holidays)) {

                    _.map(holidays, (holiday) => {
                        let HS = moment(`Sun nov 14 2021 ${holiday.startTime}:00 GMT${offSet}`).format('h:mm a')
                        let HE = moment(`Sun nov 14 2021 ${holiday.endTime}:00 GMT${offSet}`).format('h:mm a')

                        if (!this.timeSlotsClash(TS, TE, HS, HE)) {
                            checkBookedSlots()

                        } else {
                            console.log("Clash (holiday)", aTime.format('h:mm a'))
                            timeOff.push(aTime.format('h:mm a'))
                        }
                    })
                    filterTimeSlots()

                } else if (!_.isEmpty(leaves)) {

                    _.map(leaves, (leave) => {
                        let LS = moment(`Sun nov 14 2021 ${leave.startTime}:00 GMT${offSet}`).format('h:mm a')
                        let LE = moment(`Sun nov 14 2021 ${leave.endTime}:00 GMT${offSet}`).format('h:mm a')

                        if (!this.timeSlotsClash(TS, TE, LS, LE)) {
                            checkBookedSlots()

                        } else {
                            console.log("Clash (leave)", aTime.format('h:mm a'))
                            timeOff.push(aTime.format('h:mm a'))
                        }
                    })
                    filterTimeSlots()
                }
                else {
                    checkBookedSlots()
                }
            }

            // Incrementor
            aTime = moment(aTime).add(TIME_SLOT_SIZE, 'minutes');

        }

        this.setState({ timeSlots })
    }

    toggleModal = async (cancel) => {

        let offSet = moment().tz(this.props.timeZone).format('Z');

        if (_.isEmpty(this.props.services) && !cancel) {

            this.setState((prevState) => {
                return { isWarningModalOpen: !prevState.isWarningModalOpen }
            })

        }
        else if (this.props.edit && !cancel) {

            // let {firstName, lastName, phone, email} = this.props.selectedAppointment.customer
            let { firstName, gender, lastName, email, mobileNo, appointmentDate, startTime, endTime, id, specialNote, fee } = this.props.selectedAppointment
            let serviceId = this.props.selectedAppointment.serviceId
            let employeeId = this.props.selectedAppointment.employeeId

            let specialNotes = specialNote
            let date = new Date(appointmentDate)
            let service = _.find(this.props.services, (a) => { return a.serviceId === serviceId })
            let employee = _.find(this.props.employees, (a) => { return a.employeeId === employeeId })
            this.props.selectedAppointment.service = service
            this.props.selectedAppointment.employee = employee


            await this.setState({ id, firstName, gender, lastName, mobileNo, email, startTime, endTime, specialNotes, modalType: 'UPDATE', service, originalData: this.props.selectedAppointment, amount: fee })
            this.setState((prevState) => {
                return { isModalOpen: !prevState.isModalOpen }
            })
            await this.setState({ phoneError: '' })

            //Call select service function
            this.onSelectService(this.state.service)

            //Set employee
            this.onSelectEmployee(employee)
            await this.setState({ appointmentDate: date, employee })


            //Call select date function
            this.onSelectDate(date, {})

            //Set selected time slot
            let appointmentStartTime = moment(`Sun nov 14 2021 ${this.state.startTime}:00 GMT${offSet}`).format('h:mm a')
            await this.setState({ selectedTimeSlot: appointmentStartTime })

            updatedData = {} //As a fail safe

        } else {
            const defaultState = {
                ...DEFAULT_MODAL_STATE,
                appointmentsArray: [],
                recurredAppointmentArray: [],
            }

            this.setState((prevState) => {
                return { ...defaultState, isModalOpen: !prevState.isModalOpen }
            })
            this.setState({ phoneError: '' })

            updatedData = {} //As a fail safe
        }
    }

    handleModalClose = () => {
        this.setState((prevState) => {
            return { isWarningModalOpen: !prevState.isWarningModalOpen }
        })
    }

    checkChanges = (field) => {
        if (this.state.modalType === 'UPDATE') {
            if (field === 'firstName' || field === 'lastName' || field === 'mobileNo' || field === 'email') {
                let originalValue = this.state.originalData[field]

                if (originalValue !== this.state[field]) {
                    updatedData = this.state.originalData
                    updatedData[field] = this.state[field]
                    // let fullName = this.state.firstName +' '+ this.state.lastName
                    // updatedData.customer.name = fullName

                }
                else {
                    delete updatedData['customer']
                }
            }

            if (field === 'specialNote') {
                let originalValue = this.state.originalData[field]

                if (originalValue !== this.state[field]) {
                    updatedData[field] = this.state[field]
                }
                else {
                    delete updatedData[field]
                }
            }
            if (field === 'appointmentDate') {
                let originalValue = new Date(this.state.originalData[field])

                if (originalValue !== new Date(this.state[field])) {
                    updatedData[field] = new Date(this.state[field])
                }
                else {
                    delete updatedData[field]
                }
            }

            if (field === 'service') {
                let originalValue = this.state.originalData[field]

                if (originalValue.serviceId !== this.state[field].serviceId) {
                    updatedData[field] = this.state[field]
                }
                else {
                    delete updatedData[field]
                }
            }

            if (field === 'employee') {
                let originalValue = this.state.originalData[field]

                if (originalValue.employeeId !== this.state[field].employeeId) {
                    updatedData[field] = this.state[field]
                }
                else {
                    delete updatedData[field]
                }
            }

            if (field === 'selectedTimeSlot') {
                let originalValue = moment(this.state.originalData.startTime).format('h:mm a')

                // Fix times
                let dateTimeString = `${moment(this.state.appointmentDate).format('YYYY-MM-DD')} ${this.state.selectedTimeSlot}`
                let startTime = moment(dateTimeString)
                let serviceTime = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute
                let endTime = moment(dateTimeString).add(serviceTime, 'minutes')

                if (originalValue !== startTime.format('h:mm a')) {

                    updatedData['startTime'] = startTime.toString()
                    updatedData['endTime'] = endTime.toString()
                }
                else {
                    delete updatedData['startTime']
                    delete updatedData['endTime']
                }
            }

            if (_.isEmpty(updatedData)) {
                this.setState({ hasUpdates: false })
            } else {
                this.setState({ hasUpdates: true })
            }
        }
    }

    checkPhoneValidity = (text) => {
        var isValid = checkPhoneNumber(text);
        let phoneError = isValid ? false : "Not a valid phone number"
        this.setState({ mobileNo: text, phoneError }, () => this.checkChanges('mobileNo'))
        return isValid
    }

    checkFirstNameValidity = (text) => {
        var regex = new RegExp("^[a-zA-Z ]{3,40}/*$");
        var isValid = regex.test(text);
        let firstNameError = isValid ? false : "Please insert a valid name"
        this.setState({ firstName: text, firstNameError }, () => this.checkChanges('firstName'))
        return isValid
    }

    checkLastNameValidity = (text) => {
        const regex = new RegExp("^[a-zA-Z ]{3,40}/*$");
        const isValid = regex.test(text);
        let lastNameError = isValid ? false : "Please insert a valid name"
        this.setState({ lastName: text, lastNameError }, () => this.checkChanges('lastName'))
        return isValid
    }


    checkEmailValidity = (text) => {
        // validate
        var regex = new RegExp("^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$");
        var isValid = true
        if (!_.isEmpty(text)) isValid = regex.test(text);

        let emailError = isValid ? false : "Not a valid email address"
        this.setState({ email: text, emailError }, () => this.checkChanges('email'))
        return isValid

    }

    checkButtonDisability = () => {
        if (this.state.isAddingAppointment === true || this.state.firstNameError != '' || this.state.phoneError != '') {
            return true;
        }
    }

    isValid = () => {
        this.checkPhoneValidity(this.state.mobileNo)
        this.checkFirstNameValidity(this.state.firstName)
        this.checkEmailValidity(this.state.email)
        let valid = true;

        if (!this.state.gender) {
            valid = false
        } if (this.state.firstName === '' || this.state.firstNameError != '' || this.state.emailError != '') {
            valid = false
        } else if (this.state.mobileNo === '') {
            valid = false
        }
        else if (this.state.emailError != '') {
            valid = false
        }
        else if (_.isEmpty(this.state.service)) {
            valid = false
        }
        else if (_.isEmpty(this.state.employee)) {
            valid = false
        }
        else if (_.isNull(this.state.appointmentDate)) {
            valid = false
        }
        else if (_.isNull(this.state.selectedTimeSlot)) {
            valid = false
        }

        if (valid) {
            this.setState({ isValid: true })
            return true
        }
        else {
            this.setState({ isValid: false })
            return false
        }
    }

    // Return bool
    timeSlotsClash = (TS, TE, RS, RE) => {

        let timeslotStart = moment(`01/01/2020 ${TS}`)
        let timeslotEnd = moment(`01/01/2020 ${TE}`)

        let rangeStart = moment(`01/01/2020 ${RS}`)
        let rangeEnd = moment(`01/01/2020 ${RE}`)

        // If timeslot interval of both are equal
        let case1 = (TS === RS && TE === RE)

        // If timslot interval is less than the compared time range
        let case2 = (timeslotStart.isBetween(rangeStart, rangeEnd) || timeslotEnd.isBetween(rangeStart, rangeEnd))

        // If timeslot interval is larger than the compared time range
        let case3 = (rangeStart.isBetween(timeslotStart, timeslotEnd) || rangeEnd.isBetween(timeslotStart, timeslotEnd))

        if (case1 || case2 || case3) {
            return true
        } else {
            return false
        }

    }

    isTimeSlotExpired = (selectedDate, TS) => {

        let currentDate = moment(new Date())

        if (currentDate.isSame(selectedDate, 'day')) {
            let timeslotStart = moment(`${moment(currentDate).format('YYYY/MM/DD')} ${TS}`)
            if (timeslotStart.isBefore(currentDate)) {
                return true
            } else {
                return false
            }
        }

    }

    isAlreadyBooked = (date, startTime, facilityId) => {
        return this.state.appointmentsArray.some((curr) => {
            return curr.startTime === startTime && curr.appointmentDate === date && curr.facilityId === facilityId
        })
    }

    onSaveAndNext = () => {
        if (this.isValid()) {
            let dateTimeString = `${moment(this.state.appointmentDate).format('YYYY/MM/DD')} ${this.state.selectedTimeSlot}`
            let appointmentDate = moment(this.state.appointmentDate).format('YYYY-MM-DD')
            let startTime = moment(dateTimeString).format('HH:mm')
            let serviceTime = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute
            let endTime = moment(dateTimeString).add(serviceTime, 'minutes').format('HH:mm')

            let appointment = {
                branchId: this.props.branchId,
                shopId: this.props.shopId,
                isWalking: "true",
                guestCustomer: false,
                email: this.state.email,
                employeeId: this.state.employee.employeeId,
                serviceId: this.state.service.serviceId,
                gender: this.state.gender,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                mobileNo: this.state.mobileNo,
                appointmentDate: appointmentDate,
                startTime: startTime.toString(),
                endTime: endTime.toString(),
                specialNote: this.state.specialNotes,
                amount: this.state.amount || this.state.service.serviceFullPrice,
                halfCourtName: this.state.halfCourtName,
                isPartial: this.state.isPartial,
                isFullDay: false,
            }

            if (this.isAlreadyBooked(appointmentDate, startTime, appointment.facilityId)) {
                toast("Slot has already booked", { className: 'toast-custom', type: toast.TYPE["ERROR"] })
                return false;
            };

            let finalAppointmentArray = this.state.appointmentsArray;
            finalAppointmentArray.push(appointment);

            this.setState({ appointmentsArray: finalAppointmentArray });
            this.setState({ employee: {} });
            this.setState({ service: {} });
            this.setState({ appointmentDate: null });
            this.setState({ startTime: null });
            this.setState({ selectedTimeSlot: null });

            if (this.state.appointmentType === enumAppointment.RECURRING) {
                this.setState({ recurringMoment: true });
            }
        }
    }

    onSubmit = () => {
        const callback = async (addAppointmentResponse) => {
            const res = addAppointmentResponse && addAppointmentResponse.data;

            if (!res) {
                await confirm({
                    title: null,
                    message: addAppointmentResponse,
                    confirmText: "Ok",
                    cancelText: null,
                    confirmColor: "primary",
                    cancelColor: "link text-danger"
                });
                this.setState({ isAddingAppointment: false, isError: true, errorMessage: addAppointmentResponse })
            }
            else if (res && (!res.data.unavailableAppointmentList || !res.data.unavailableAppointmentList.length)) {
                // if unavailableAppointmentList is null/empty
                this.setState({ ...DEFAULT_MODAL_STATE })
                notify('Appointment added successfully', 'SUCCESS');

                this.setState({ appointmentsArray: [] })
                this.setState({ recurredAppointmentArray: [] })

                let branchId = this.props.selectedBranch.branchId;
                this.props.getAppointments(branchId);

                // Close the modal after adding the appointment
                this.props.toggleAddAppointmentModal();

            } else if (res && res.data.unavailableAppointmentList) {
                // for multiple & recurring appoinments
                let result = await confirm({
                    title: (
                        <ul>
                            {res?.data && res?.data.unavailableAppointmentList.map(ap => <div>
                                <li>{ap.serviceName} {ap.appointmentDate} {ap.startTime} </li>
                            </div>)}
                        </ul>
                    ),
                    message: "Sorry!, above slots you have picked are not available, Do you want to book other slots ?",
                    confirmText: "Yes",
                    cancelText: "No",
                    confirmColor: "primary",
                    cancelColor: "link text-danger"
                });

                if (!result) {
                    this.setState({ ...DEFAULT_MODAL_STATE })
                } else {
                    const status = result ? 'Active' : 'Rejected';

                    let availList = res.data.availableAppointmentList.map(ap => {
                        return {
                            ...ap,
                            halfCourtName: ap.courtName,
                            isPartial: ap.partial,
                            amount: ap.fee,
                            isWalking: "true",
                            appointmentDate: moment(ap.appointmentDate).format('YYYY-MM-DD'),
                            status
                        }
                    })

                    const submitObject = {
                        appointmentDTORqList: availList,
                        appointmentType: this.state.appointmentType
                    }

                    this.props.confirmAppointment(submitObject, callback)
                }
            }
        }

        if (this.state.appointmentType === enumAppointment.SINGLE && !this.isValid())
            return false;
        // if (this.isValid()) {

        // let phone = this.state.phone
        // let fullPhone = configs.countryCode + phone.slice(2, phone.length)
        // let fullName = this.state.firstName +' '+ this.state.lastName

        // let customer = {
        //     uid: this.state.phone,
        //     firstName: this.state.firstName,
        //     lastName: this.state.lastName,
        //     name: fullName,
        //     phone: fullPhone,
        //     image: DEFAULT_PROFILE_IMAGE,
        //     isWalking: true
        // }

        // let service = {
        //     id: this.state.service.id,
        //     name: this.state.service.name,
        //     serviceTime: this.state.service.serviceTime,
        // }

        // let employee = {
        //     id: this.state.employee.id,
        //     name: this.state.employee.name,
        //     image: this.state.employee.image
        // }

        // Fix times
        let dateTimeString = `${moment(this.state.appointmentDate).format('YYYY/MM/DD')} ${this.state.selectedTimeSlot}`
        let appointmentDate = moment(this.state.appointmentDate).format('YYYY-MM-DD')
        let startTime = moment(dateTimeString).format('HH:mm')
        let serviceTime = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute
        let endTime = moment(dateTimeString).add(serviceTime, 'minutes').format('HH:mm')

        let appointment = {
            branchId: this.props.branchId,
            shopId: this.props.shopId,
            isWalking: "true",
            guestCustomer: false,
            email: this.state.email,
            employeeId: this.state.employee.employeeId,
            serviceId: this.state.service.serviceId,
            gender: this.state.gender,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            mobileNo: this.state.mobileNo,
            appointmentDate: appointmentDate,
            startTime: startTime.toString(),
            endTime: endTime.toString(),
            specialNote: this.state.specialNotes,
            amount: this.state.amount || this.state.service.serviceFullPrice,
            halfCourtName: this.state.halfCourtName,
            isPartial: this.state.isPartial,
            isFullDay: false,
        }

        this.setState({ isAddingAppointment: true, isError: false, errorMessage: '' })

        let finalAppointmentArray = [];

        if (this.state.appointmentType === enumAppointment.MULTIPLE) {
            finalAppointmentArray = this.state.appointmentsArray;
        } else if (this.state.appointmentType === enumAppointment.SINGLE) {
            finalAppointmentArray = [appointment];
        } else if (this.state.appointmentType === enumAppointment.RECURRING) {
            finalAppointmentArray = this.state.recurredAppointmentArray.length > 0 ? this.state.recurredAppointmentArray : [appointment];
        }

        const submitObject = {
            appointmentDTORqList: finalAppointmentArray,
            appointmentType: this.state.appointmentType
        }

        this.props.submitAppointment(submitObject, callback)
        // }
    }

    onUpdate = () => {

        const callback = (success, updatedData) => {
            if (success) {
                // Fix times
                let dateTimeString = `${moment(this.state.appointmentDate).format('YYYY-MM-DD')} ${this.state.selectedTimeSlot}`
                let startTime = moment(dateTimeString)
                let endTime = moment(dateTimeString).add(this.state.service.serviceTime, 'minutes')

                this.props.setChanges(this.state.employee, this.state.service, this.state.appointmentDate, startTime, this.state.specialNotes, endTime, this.props.selectedAppointment.id, updatedData)
                this.setState({ ...DEFAULT_MODAL_STATE })
                notify('Appointment updated successfully', 'SUCCESS')
            } else {
                this.setState({ isAddingAppointment: false, isError: true, errorMessage: 'Something went wrong! Please try again' })
            }
        }

        let dateTimeString = `${moment(this.state.appointmentDate).format('YYYY/MM/DD')} ${this.state.selectedTimeSlot}`
        let appointmentDate = moment(this.state.appointmentDate).format('YYYY-MM-DD')
        let startTime = moment(dateTimeString).format('HH:mm')
        let serviceTime = (this.state.service.serviceHour * 60) + this.state.service.serviceMinute
        let endTime = moment(dateTimeString).add(serviceTime, 'minutes').format('HH:mm')

        const updatedAppointment = {
            branchId: this.props.branchId,
            shopId: this.props.shopId,
            id: this.state.originalData.id,
            isWalking: "true",
            guestCustomer: this.props.selectedAppointment.guestCustomer,
            email: this.state.email,
            employeeId: this.state.employee.employeeId,
            serviceId: this.state.service.serviceId,
            gender: this.state.gender,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            mobileNo: this.state.mobileNo,
            appointmentDate: appointmentDate,
            startTime: startTime.toString(),
            endTime: endTime.toString(),
            specialNote: this.state.specialNotes,
            amount: this.state.service.serviceFullPrice,
            halfCourtName: this.state.halfCourtName,
            isPartial: this.state.isPartial,
            serviceName: this.state.service.serviceName,
            employeeFirstName: this.state.employee.employeeFirstName,
            employeeLastName: this.state.employee.employeeLastName,
            isFullDay: false,
        }

        this.setState({ isAddingAppointment: true, isError: false, errorMessage: '' })
        this.props.updateAppointment(this.state.originalData.id, updatedAppointment, callback)
    }


    onSelectService = (service) => {

        let employees = []  // Employees of the selected service

        _.forEach(this.props.employees, (obj) => {
            let serviceIds = _.get(obj, 'serviceId', [])
            let includes = _.includes(serviceIds, service.serviceId)
            if (includes) {
                employees.push(obj)
            }
        })

        this.setState({ service: service, employees: employees, employee: {}, appointmentDate: null, selectedTimeSlot: null, disabledCalendarDates: null }, () => this.checkChanges('service'))
    }

    onSelectEmployee = async (employee) => {
        let disabledCalendarDates = await this.getDisabledCalendarDates(employee.employeeId)
        this.setState({ employee: employee, disabledCalendarDates: disabledCalendarDates, appointmentDate: null, selectedTimeSlot: null }, () => this.checkChanges('employee'))
    }

    onSelectDate = (appointmentDate, modifiers = {}) => {
        let offSet = moment().tz(this.props.timeZone).format('Z');
        if (!modifiers.disabled) {
            // const shopOpeningHours = _.get(this.props.openingHours, `${moment(new Date(date)).format('dddd').toLowerCase()}`, null)
            let dayName = moment(new Date(appointmentDate)).format('dddd')
            let shopOpeningHours = _.filter(this.props.openingHours, (a) => { return a.day === dayName })
            if (shopOpeningHours !== null) {
                // Sun nov 14 2021 ${value.openTime}:00 GMT+0530

                let ot = _.get(shopOpeningHours[0], "openTime", null);
                let ct = _.get(shopOpeningHours[0], "closeTime", null);
                let openingTime = new Date(`Sun nov 14 2021 ${ot}:00 GMT${offSet}`)
                let closingTime = new Date(`Sun nov 14 2021 ${ct}:00 GMT${offSet}`)

                let bookedSlots = this.getBookedSlots(appointmentDate)
                console.log("BOOKED SLOTS", bookedSlots)
                this.setState({ appointmentDate: appointmentDate, selectedTimeSlot: null }, () => this.checkChanges('appointmentDate'))

                // get service time in minutes
                let serviceTime = parseInt(this.state.service.serviceHour * 60) + parseInt(this.state.service.serviceMinute)

                // build time slots for appointment time
                this.buildtimeSlots(serviceTime, this.state.employee.employeeId, appointmentDate, bookedSlots, openingTime, closingTime)
            }
        }
    }

    // TODO: Check for fully booked days and disable them
    getDisabledCalendarDates = (employeeId) => {
        return new Promise((resolve, reject) => {

            let dates = []
            let before = { before: new Date() }     // Disable days before today

            let daysOfWeek = []     // Array of numbers corresponding to the day of the week (Ex: sunday is 0, saturday is 6)
            _.forEach(this.props.openingHours, (value, key) => {
                if (value.status === "Inactive") {
                    daysOfWeek.push(WEEK_MAP[value.day])
                }
            })

            let shopClosedDates = { daysOfWeek: daysOfWeek }

            _.forEach(this.props.leaves, (obj) => {
                if (employeeId === obj.employeeId && _.isEmpty(obj.startTime)) {
                    dates.push(new Date(obj.leaveDate))    // leave dates
                }
            })

            _.forEach(this.props.holidays, (obj) => {
                if (_.isEmpty(obj.startTime)) {
                    dates.push(new Date(obj.holiday))    // shop holiday dates
                }
            })

            dates.push(shopClosedDates)
            dates.push(before)
            console.log('disabledCalendarDates', dates)

            resolve(dates)
        })
    }

    getBookedSlots = (selectedDate) => {
        let offSet = moment().tz(this.props.timeZone).format('Z');
        let bookedSlots = []

        _.forEach(this.props.appointments, (appoint) => {

            if (this.state.employee.employeeId === appoint.employeeId) {
                if (moment(new Date(appoint.appointmentDate)).isSame(selectedDate, 'day')) {
                    bookedSlots.push({
                        appointmentDate: new Date(appoint.appointmentDate),
                        startTime: new Date(`Sun nov 14 2021 ${appoint.startTime}:00 GMT${offSet}`),
                        endTime: new Date(`Sun nov 14 2021 ${appoint.endTime}:00 GMT${offSet}`)
                    })
                }
            }
        })

        //If it is edit mode remove from the booked slots
        if (this.props.edit) {
            _.remove(bookedSlots, (bookedSlot) => {
                return bookedSlot.startTime == this.state.startTime
            });
        }

        return bookedSlots
    }

    renderServicesDropDown = () => {
        const servicesIdMap = this.props.serviceGroups?.reduce((map, obj) => {
            map[obj.groupId] = obj;
            return map;
        }, {});

        const activeServices = this.props.services.filter(s => s.status === 'Active' && s.employeeId?.length > 0);
        // Group services by their groupId and include group id and order
        const groupedServices = activeServices.reduce((result, item) => {
            const groupId = item.serviceGroup.groupId;
            const groupOrder = item.serviceGroup.order;
            let group = result.find(group => group.id === groupId);
            if (!group) {
                group = {id: groupId, order:groupOrder, service:[]};
                result.push(group);
            }
            group.service.push(item);
            return result;
        }, []);

         // Sort services within each group by the 'order' attribute
        groupedServices.forEach(group =>{
            group.service.sort((a,b) => a.order - b.order);
        });
         // Sort groups by the 'order' attribute
        groupedServices.sort((a,b) => a.order - b.order);

        let count = 0;

        return (
            <UncontrolledDropdown disabled={this.state.isAddingAppointment}>
                <DropdownToggle
                    // className="w-75 text-left"
                    caret color={`${_.isEmpty(this.state.service) && !this.state.isValid ? 'danger' : 'info'}`}>
                    {_.isEmpty(this.state.service) ? 'select' : this.state.service.serviceName}
                </DropdownToggle>
                <DropdownMenu className="w-100"
                    modifiers={{
                        setMaxHeight: {
                            enabled: true,
                            order: 890,
                            fn: (data) => {
                                return {
                                    ...data,
                                    styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                        maxHeight: '360px',
                                        zIndex: 2000,
                                        left: '2px'
                                    },
                                };
                            },
                        },
                    }}>
                    {_.map(groupedServices, (group) => {
                        count += 1;
                        return (
                            <div key={group.id} style={{ backgroundColor: count % 2 === 0 ? '#f0f0f0' : '#fff' }}>
                                <div className="dropdown-header pl-2" style={{ 'font-weight': '800' }}>{group.service[0].serviceGroup?.groupName}</div>
                                {_.map(group.service, (obj, index) => {
                                    return (
                                        <DropdownItem
                                            key={index}
                                            // disabled={!obj.available}
                                            onClick={() => this.onSelectService(obj)}
                                            className="d-flex align-items-baseline px-2"
                                        >
                                            <div className="d-flex flex-column" style={{ width: "calc(100% - 60px)" }}>
                                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{`${obj.serviceName} ${obj.status !== "Active" ? '(Unavailable)' : ''}`}</div>
                                                <p className="text-muted" style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} >{_.get(obj, "description", "")}</p>
                                            </div>
                                            <div className="ml-auto" >{configs.currencyPrefix}{_.get(obj, "serviceFullPrice", "")}</div>
                                        </DropdownItem>
                                    )
                                })}
                            </div>
                        );

                    })}
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }
    renderEmployeesDropDown = () => {
        if (!_.isEmpty(this.state.service)) {
            return (
                <FormGroup>
                    <Label ><span class="required">*</span>{businessTypes(this.props.businessTypeId, 'business_category_singular')}</Label>
                    <UncontrolledDropdown  >
                        <DropdownToggle
                            disabled={this.state.isAddingAppointment}
                            // className="w-50 text-left"
                            caret color={`${_.isEmpty(this.state.employee) && !this.state.isValid ? 'danger' : 'info'}`}>
                            {_.isEmpty(this.state.employee) ? 'select' : this.state.employee.employeeFirstName + ' ' + this.state.employee.employeeLastName}
                        </DropdownToggle>
                        <DropdownMenu className="w-50"
                            modifiers={{
                                setMaxHeight: {
                                    enabled: true,
                                    order: 890,
                                    fn: (data) => {
                                        return {
                                            ...data,
                                            styles: {
                                                ...data.styles,
                                                overflow: 'auto',
                                                maxHeight: '280px',
                                                zIndex: 2000,
                                            },
                                        };
                                    },
                                },
                            }}>
                            {_.map(this.state.employees, (obj, index) => {
                                return (
                                    obj.status === "Active" && <DropdownItem
                                        key={index}
                                        disabled={obj.status === "Active" ? false : true}
                                        onClick={() => this.onSelectEmployee(obj)}
                                        className="d-flex align-items-baseline px-1"
                                    >
                                        <div className="d-flex flex-column" style={{ width: "calc(100%)" }}>
                                            <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{obj.employeeFirstName} {obj.employeeLastName}</p>
                                        </div>
                                    </DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </FormGroup>
            )
        }
    }

    onChangeAppointmentType = (appointmentType) => {
        this.setState({ appointmentType })
    }

    renderAppointmentTypeDropDown = () => {
        return (
            <FormGroup>
                <div className="d-flex">
                    <Label ><span class="required">*</span>Appointment type</Label>
                    <InfoTooltip
                        image={Images.info}
                        className="info-list-tooltip pointer"
                        text={
                            <ul>
                                <li>Single - Add one-time appointment for a selected service type</li>
                                <li>Multiple - Add more than one appointment for several services</li>
                                <li>Recurring - Add appointments to recur after defined frequency until defined end date</li>
                            </ul>}
                    />
                </div>
                <UncontrolledDropdown disabled={this.state.appointmentsArray.length > 0 && (this.state.appointmentType === enumAppointment.RECURRING || this.state.appointmentType === enumAppointment.MULTIPLE) ? true : false}>
                    <DropdownToggle
                        caret color='info'>
                        {_.isEmpty(this.state.appointmentType) ? 'select' : this.state.appointmentType}
                    </DropdownToggle>
                    <DropdownMenu className="w-50"
                        modifiers={{
                            setMaxHeight: {
                                enabled: true,
                                order: 890,
                                fn: (data) => {
                                    return {
                                        ...data,
                                        styles: {
                                            ...data.styles,
                                            overflow: 'auto',
                                            maxHeight: '280px',
                                            zIndex: 2000,
                                        },
                                    };
                                },
                            },
                        }}>
                        <DropdownItem
                            key={1}
                            className="d-flex align-items-baseline px-1"
                            onClick={() => this.onChangeAppointmentType(enumAppointment.SINGLE)}
                        >
                            <div className="d-flex flex-column" style={{ width: "calc(100%)" }}>
                                <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{enumAppointment.SINGLE}</p>
                            </div>
                        </DropdownItem>
                        <DropdownItem
                            key={2}
                            className="d-flex align-items-baseline px-1"
                            onClick={() => this.onChangeAppointmentType(enumAppointment.MULTIPLE)}
                        >
                            <div className="d-flex flex-column" style={{ width: "calc(100%)" }}>
                                <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{enumAppointment.MULTIPLE}</p>
                            </div>
                        </DropdownItem>
                        <DropdownItem
                            key={2}
                            className="d-flex align-items-baseline px-1"
                            onClick={() => this.onChangeAppointmentType(enumAppointment.RECURRING)}
                        >
                            <div className="d-flex flex-column" style={{ width: "calc(100%)" }}>
                                <p style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{enumAppointment.RECURRING}</p>
                            </div>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </FormGroup>
        )
    }

    onTimeSlotSelect = (time) => {
        this.setState({ selectedTimeSlot: time })
        this.checkChanges('selectedTimeSlot')
    }

    removeAppointmentFromBulk = (i) => {
        const _appointmentsArray = this.state.appointmentsArray;
        _appointmentsArray.splice(i, 1);
        this.setState({ appointmentsArray: [..._appointmentsArray] });
    }

    setRecurredAppointmentArray = (recurredAppointmentArray) => {
        this.setState({ recurredAppointmentArray: recurredAppointmentArray });
    }

    renderTime = (timeSlots) => {
        const relatedSlots = [...timeSlots];
        const serviceTime = parseInt(this.state.service.serviceHour * 60) + parseInt(this.state.service.serviceMinute)

        const filteredSlots  = this._filterUnavailableGaps(this.state.appointmentsArray, relatedSlots, serviceTime, this.state.appointmentDate);

        if (this.state.appointmentDate) {
            return (
                <FormGroup>
                    <Label ><span class="required">*</span>Time</Label>
                    {!_.isEmpty(relatedSlots) ?
                        <UncontrolledDropdown>
                            <DropdownToggle
                                disabled={this.state.isAddingAppointment}
                                className="w-50 text-left"
                                caret
                                color='info'>
                                {_.isEmpty(this.state.selectedTimeSlot) ? 'select' : this.state.selectedTimeSlot}
                            </DropdownToggle>
                            <DropdownMenu className="w-50"
                                modifiers={{
                                    setMaxHeight: {
                                        enabled: true,
                                        order: 890,
                                        fn: (data) => {
                                            return {
                                                ...data,
                                                styles: {
                                                    ...data.styles,
                                                    overflow: 'auto',
                                                    maxHeight: '400px',
                                                    zIndex: 2000
                                                },
                                            };
                                        },
                                    },
                                }}>
                                {_.map(filteredSlots, (time, index) => {

                                    return (
                                        <DropdownItem
                                            key={index}
                                            onClick={() => this.onTimeSlotSelect(time)}>
                                            <div className="d-flex justify-content-between">
                                                <div>{time}</div>
                                            </div>
                                        </DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        : <div> &emsp; Not available, Check another date </div>}
                </FormGroup>
            )
        }
    }

    renderModal = () => {

        return (
            <Modal isOpen={this.props.isHandleByRedux ? this.props.addAppointmentModalConfig.isOpen : this.state.isModalOpen} id="add-appoint-modal" >
                <ModalHeader >{this.props.edit ? "Update Appointment" : "New Appointment"}</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label><span class="required">*</span>First Name</Label>
                                    <Input
                                        id="firstName"
                                        value={this.state.firstName}
                                        invalid={typeof this.state.firstNameError === "boolean" ? this.state.firstNameError : this.state.firstNameError !== ''}
                                        placeholder="Enter customer first name"
                                        disabled={this.state.isDisabledCustomerfirstName}
                                        className={`border ${this.state.firstName === '' && !this.state.isValid ? 'border-danger' : ''}`}
                                        onChange={(e) => this.checkFirstNameValidity((e.target.value))} />
                                    <FormFeedback>{this.state.firstNameError}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Last Name</Label>
                                    <Input
                                        id="lastName"
                                        value={this.state.lastName}
                                        invalid={typeof this.state.lastNameError === "boolean" ? this.state.lastNameError : this.state.lastNameError !== ''}
                                        placeholder="Enter customer last name"
                                        onChange={(e) => this.checkLastNameValidity((e.target.value))}
                                        disabled={this.state.isDisabledCustomerlastName} />
                                    <FormFeedback>{this.state.lastNameError}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label >Email Address (Optional)</Label>
                                    <Input
                                        id="email"
                                        type="email"
                                        invalid={typeof this.state.emailError === "boolean" ? this.state.emailError : this.state.emailError !== ''}
                                        value={this.state.email.includes('walkingrandom') ? '' : this.state.email}
                                        placeholder={!this.props.isEditing && "Enter Email"}
                                        disabled={this.state.isDisabledCustomeremail}
                                        className={`border`}
                                        onChange={(e) => this.checkEmailValidity(e.target.value)}
                                    />
                                    <FormFeedback>{this.state.emailError}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label ><span class="required">*</span>Phone</Label>
                                    <Input
                                        id="phone"
                                        type="tel"
                                        invalid={typeof this.state.phoneError === "boolean" ? this.state.phoneError : this.state.phoneError !== ''}
                                        Value={this.state.mobileNo}
                                        placeholder={!this.props.isEditing && "Enter customer phone"}
                                        disabled={this.state.isDisabledCustomermobileNo}
                                        className={`w-100 border ${this.state.mobileNo === '' && !this.state.isValid ? 'border-danger' : ''}`}
                                        onChange={(e) => this.checkPhoneValidity(e.target.value)} />
                                    <FormFeedback>{this.state.phoneError}</FormFeedback>
                                    <FormText>{configs.phoneNumberExample}</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label><span class="required">*</span>Gender</Label>
                                    <Input
                                        type="select"
                                        id="gender"
                                        value={this.state.gender}
                                        onChange={(e) => this.setState({ gender: e.target.value })}
                                        className={`border ${!this.state.gender && !this.state.isValid ? 'border-danger' : ''}`}
                                        disabled={this.state.isDisabledCustomergender}
                                    >
                                        <option value="" disabled>Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </Input>
                                    <FormFeedback>{this.state.firstNameError}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>

                        {/* <FormGroup>
                            {this.renderAppointmentTypeDropDown()}
                        </FormGroup> */}

                        {!this.state.recurringMoment && <FormGroup>
                            <Label ><span class="required">*</span>{businessTypes(this.props.businessTypeId, 'service')}</Label>
                            {this.renderServicesDropDown()}
                        </FormGroup>}

                        {this.renderEmployeesDropDown()}

                        {!_.isEmpty(this.state.employee) &&
                            <FormGroup >
                                <Label ><span class="required">*</span>Date</Label>
                                <div className="text-center">
                                    <DayPicker
                                        onDayClick={this.onSelectDate}
                                        selectedDays={this.state.appointmentDate}
                                        modifiers={{
                                            disabled: this.state.disabledCalendarDates
                                        }}
                                        firstDayOfWeek={1}
                                    />
                                </div>
                            </FormGroup>
                        }
                        {this.renderTime(this.state.timeSlots)}
                        {this.state.selectedTimeSlot &&
                             <FormGroup>
                                <Label >Service Charge {configs.currencyPrefix}{Number.parseFloat(`${this.state.service.serviceFullPrice}`).toFixed(2)}</Label>
                            </FormGroup>
                        }
                        <FormGroup>
                            <Label >Special Notes</Label>
                            <TextareaAutosize
                                placeholder="Add special notes (Optional)"
                                className="note-area"
                                value={this.state.specialNotes}
                                minRows={4}
                                maxRows={7}
                                disabled={this.state.isAddingAppointment}
                                onChange={(e) => { this.setState({ specialNotes: e.target.value }, () => this.checkChanges('specialNote')) }}
                            />
                        </FormGroup>

                        {this.state.appointmentsArray.length > 0 && this.state.appointmentType === enumAppointment.MULTIPLE && <MultipleAppointmentGrid appointments={this.state.appointmentsArray} removeAppointmentFromBulk={this.removeAppointmentFromBulk} />}
                        {this.state.appointmentsArray.length > 0 && this.state.appointmentType === enumAppointment.RECURRING && <RecurringAppointmentPanel appointments={this.state.appointmentsArray} setRecurredAppointmentArray={this.setRecurredAppointmentArray} />}

                    </Form>

                    {/* Validation message */}
                    {!this.state.isValid && <div className="text-danger">Please provide all the required information</div>}
                    {/* Error message */}
                    {this.state.isError && <div className="text-danger">{this.state.errorMessage}</div>}

                </ModalBody>

                <ModalFooter style={{ justifyContent: 'flex-start' }}>
                    <Button color="secondary" disabled={this.state.isAddingAppointment} onClick={() => {
                        if (this.props.isHandleByRedux) {
                            this.resetModalWithProps()
                        } else {
                            this.toggleModal(true)
                        }
                    }}>Cancel</Button>
                    {!this.props.edit && this.state.appointmentType === enumAppointment.MULTIPLE && <Button color="secondary" disabled={this.state.isAddingAppointment} onClick={this.onSaveAndNext}>Add</Button>}
                    {this.state.appointmentsArray.length === 0 && this.state.appointmentType === enumAppointment.RECURRING && <Button color="secondary" disabled={this.state.isAddingAppointment} onClick={this.onSaveAndNext}>Recur</Button>}
                    {this.props.edit ?
                        <Button
                            disabled={this.checkButtonDisability()}
                            onClick={() => this.onUpdate()}
                            style={{ width: '76px', backgroundColor: "#f76969", borderColor: 'transparent' }}   // TODO:  UI - Need a responsive solution instead of 76px
                        >
                            {this.state.isAddingAppointment ? <Spinner color="white" size="sm" /> : 'Update'}
                        </Button> :
                        (this.state.appointmentType === enumAppointment.MULTIPLE || this.state.appointmentType === enumAppointment.RECURRING) && this.state.appointmentsArray.length === 0 ? '' :
                            <Button
                                disabled={this.checkButtonDisability()}
                                onClick={() => this.onSubmit()}
                                style={{ width: '76px', backgroundColor: "#f76969", borderColor: 'transparent' }}   // TODO:  UI - Need a responsive solution instead of 76px
                            >
                                {this.state.isAddingAppointment ? <Spinner color="white" size="sm" /> : 'Submit'}
                            </Button>}
                </ModalFooter>
            </Modal>
        )
    }

    resetModalWithProps = (isCustomerModalOpen) => {
        const defaultState = {
            ...DEFAULT_MODAL_STATE,
            appointmentsArray: [],
            recurredAppointmentArray: [],
        }

        this.setState((prevState) => {
            return { ...defaultState, phoneError: '' }
        });

        if (isCustomerModalOpen){
            this.setState({ isOpenSearchCustomerModal: true });
        } else{
            this.props.toggleAddAppointmentModal()
        }
    }

    renderButton = () => {
        if (this.props.edit) {
            return (
                <Button className="an_new_appointment_edit_btn" size={'sm'} disabled={!this.props.isSubscribed} onClick={() => this.toggleModal(false)}>
                    <span >Edit</span>
                </Button>
            )
        } else {
            return (
                <div>
                    <Button className="an_new_appointment_btn d-none d-md-block" disabled={!this.props.isSubscribed || this.props.selectedBranch.status === "Inactive"} onClick={() => {
                        if (this.props.isHandleByRedux) {
                            this.resetModalWithProps(true)
                        } else {
                            this.toggleModal(false)
                        }
                    }}>
                        {/* <span className="d-none d-sm-block">+ New Appointment</span> */}
                        <div className="d-flex ">
                            <div className="ml-auto">
                                <img src={Images.add} height={26} alt="appointment-add-btn"></img>
                            </div>
                            <div className="pl-2">Appointment</div>
                        </div>
                    </Button>
                    <div onClick={this.props.isSubscribed && this.props.selectedBranch.status === "Active" ? () => {
                        if (this.props.isHandleByRedux) {
                            this.resetModalWithProps(true)
                        } else {
                            this.toggleModal(false)
                        }
                    } : null} className={`d-flex d-md-none flex-column fixed-bottom ${!this.props.isSubscribed || this.props.selectedBranch.status === "Inactive" ? 'an_new_appointment_flot_disabled' : 'an_new_appointment_flot'} justify-content-center align-items-center`} >
                        <div className="an_new_appointment_flot_img shadow" >
                            <PlusIcon />
                        </div>
                        <span className="an_new_appointment_flot_text" >Appointment </span>
                    </div>
                </div>
            )
        }
    }

    isValidPhoneNumber = (phoneNumber) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phoneNumber);
    }

    toggle = () =>{
        this.setState({isOpenSearchCustomerModal: false})
    }

    handleSearch = async () =>{
        const { mobileNo } = this.state;

        if (!this.state.mobileNo) {
            this.setState({ phoneError: 'Phone number is required' });
            return;
        }

        if (!this.isValidPhoneNumber(mobileNo)) {
            this.setState({ phoneError: 'Invalid phone number format' });
            return;
        }

        this.setState({ isSearching: true });


        const result = await fetchCustomers(this.props.selectedBranch.branchId, '', '', '', this.state.mobileNo, 0);
        const customers  = result.data?.data;

        this.props.toggleAddAppointmentModal();

        if (customers?.total > 0) {
            const customer = customers.pageList[0];

            this.setState({
                gender: customer.gender,
                firstName: customer.customerFirstName,
                lastName: customer.customerLastName,
                mobileNo: customer.contactNo,
                email: customer.email,
                isDisabledCustomerfirstName: true,
                isDisabledCustomerlastName: true,
                isDisabledCustomeremail: true,
                isDisabledCustomermobileNo: true,
                isDisabledCustomergender: true,
                isOpenSearchCustomerModal: false
            })
        }  else{
            this.setState({
                isOpenSearchCustomerModal: false,
                isDisabledCustomermobileNo: false,
                mobileNo: mobileNo
            })
        }
            this.setState({ isSearching: false });
    }

    _filterUnavailableGaps = (appointments, timeSlots, interval, currentSelectedDate) => {
        const _filerTimeSlotsByAppointments = (appointments, selectedDate, currentSlotStartTime, currentSlotEndTime) => {
            const selectedDateAppointments = appointments.filter(appointment =>
                moment(appointment.appointmentDate).isSame(selectedDate, 'day')
            );

            for (const { appointmentDate, startTime, endTime } of selectedDateAppointments) {
                const start = moment(`${appointmentDate} ${startTime}`);
                const end = moment(`${appointmentDate} ${endTime}`);

                if (moment(currentSlotStartTime).isBetween(start, end) ||
                    moment(currentSlotEndTime).isBetween(start, end) ||
                    moment(currentSlotStartTime).isSame(start)) {
                    return false;
                }
            }

            return true;
        }

        if (Array.isArray(appointments) && appointments.length > 0 && currentSelectedDate) {
            const filteredSlots = [];

            for (let i = 0; i < timeSlots.length; i++) {
                const currentSlot = moment(`${moment(currentSelectedDate).format('YYYY/MM/DD')} ${timeSlots[i]}`, 'YYYY/MM/DD hh:mm a');
                const nextSlot = moment(currentSlot).add(interval, 'minutes');

                if (this.state.appointmentType === enumAppointment.MULTIPLE) {
                    if (_filerTimeSlotsByAppointments(appointments, currentSelectedDate, currentSlot, nextSlot)) {
                        filteredSlots.push(timeSlots[i]);
                    }
                } else {
                    filteredSlots.push(timeSlots[i]);
                }
            }

            return filteredSlots;
        } else {
            return timeSlots;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderModal()}
                {this.state.isWarningModalOpen && <WarningModal text="Please create services and assign staff before create an appointment" onClose={this.handleModalClose} />}
                {this.renderButton()}
                <Modal isOpen={this.state.isOpenSearchCustomerModal}>
                    <ModalHeader>Search Customer</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label><span class="required">*</span>Phone Number</Label>
                            <Input
                                type="tel"
                                name="phone"
                                id="phone"
                                placeholder="Enter phone number"
                                value={this.state.mobileNo}
                                onChange={(e) => this.checkPhoneValidity(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' && !this.state.isSearching) {
                                        e.preventDefault(); // Prevent default action to avoid duplicate requests
                                        this.handleSearch();
                                    }
                                }}
                                invalid={typeof this.state.phoneError === "boolean" ? this.state.phoneError : this.state.phoneError !== ''}
                            />
                            <FormFeedback>{this.state.phoneError}</FormFeedback>
                            <FormText>{configs.phoneNumberExample}</FormText>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.handleSearch}
                            disabled={this.state.isSearching} >
                            {this.state.isSearching ? <Spinner size="sm" /> : 'Search'}
                        </Button>
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        serviceGroups: state.shop.serviceGroups,
        addAppointmentModalConfig: state.baseComponents.addAppointmentModalConfig
    };
};

export default connect(mapStateToProps, { getAppointments, confirmAppointment, toggleAddAppointmentModal, getCustomers, configAddAppointmentModal })(AddAppointmentModal);
