import React, { useState } from "react";
import { NavLink, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import {
    Button,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import * as _ from 'lodash';
import Images from "../Images/Images";
import moment from 'moment'

import { ReactComponent as AppointmentsIcon } from "../../assets/images/appointments.svg"
import { ReactComponent as EmployeesIcon } from "../../assets/images/employees.svg"
import { ReactComponent as ServiceIcon } from "../../assets/images/service.svg"
import { ReactComponent as CustomerIcon } from "../../assets/images/customer.svg"
import { ReactComponent as CovidIcon } from "../../assets/images/covid.svg"
import { ReactComponent as LogoutIcon } from "../../assets/images/logout.svg"
import { ReactComponent as SettingsIcon } from "../../assets/images/settings.svg"
import { ReactComponent as AnalyticsIcon } from "../../assets/images/analytics.svg"
import { ReactComponent as SupportIcon } from "../../assets/images/support.svg"
import { ReactComponent as DashboardIcon } from "../../assets/images/dashboard.svg"
import { ReactComponent as SportsIcon } from "../../assets/images/sports.svg"
import { ReactComponent as FacilityIcon } from "../../assets/images/facility.svg"
import { ReactComponent as DrivingIcon } from "../../assets/images/learnersServices.svg"
import { ReactComponent as AdminIcon } from "../../assets/images/customer-support.svg"
import { businessTypes, configs } from "../../utils/configs"

import './_sidebar.css'

import AddAppointmentModal from "../../components/AddAppointmentModal/AddAppointmentModal"
import AddAppointmentForFacilityModal from "../AddAppointmentForFacilityModal/AddAppointmentForFacilityModal";
import { submitAppointment, signOut, selectBranch } from '../../actions'
import { useEffect } from "react";
import {APPOINTMENT_STATUS, getUserId, isSuperAdmin} from "../../utils/common";


function SideBar(props) {
    const [employee, setEmployee] = useState({ value: null, loading: true });

    useEffect(() => {
        if (!isSuperAdmin()) {
            let userId = getUserId();
            setEmployee({ value: props.employees.find((e) => e.userId == userId), loading: false })
        } else {
            setEmployee({ value: null, loading: false })
        }
    }, [props])

    function checkServicesIcon() {
        switch (businessTypes(props.businessTypeId, 'serviceLogo')) {
            case 'serviceIcon':
                return <ServiceIcon />
            case 'sportsIcon':
                return <SportsIcon />
            case 'learnersServiceIcon':
                return <DrivingIcon />
            default:
                return <ServiceIcon />
        }
    }
    function checkStaffIcon() {
        switch (businessTypes(props.businessTypeId, 'business_category_logo')) {
            case 'staffIcon':
                return <EmployeesIcon />
            case 'facilityIcon':
                return <FacilityIcon />
            case 'instructorsIcon':
                return <EmployeesIcon />
            default:
                return <EmployeesIcon />
        }
    }
    function checkSiteLogo() {
        switch (configs.country) {
            case 'LK':
                return Images.logo
            case 'AU':
                return Images.logo
            default:
                return Images.logo
        }
    }

    const [isToggle, setToggle] = useState(false)
    let history = useHistory();

    return (
        <React.Fragment>
            <nav className="navbar navbar-dark fixed-top p-0 an-navbar df" >
                <div className="navbar-brand bg-transparent col-sm-12 col-md-1 col-lg-2 mr-0 d-flex align-items-center justify-content-between pl-1 pl-md-2" >
                    <a className="d-flex" href="/" >
                        <img className="an-nav-avatar" src={checkSiteLogo()} alt="site-logo" title={configs.siteName} />
                    </a>
                    <div className="d-md-none" >
                        {props.businessMode === 'EmployeeMode' &&
                            <AddAppointmentModal
                                shopId={props.shopId}
                                services={props.services}
                                employees={props.employees}
                                openingHours={props.shopTimeArr}   // NEW
                                openingTime={props.openingTime}    // Opening hour - 9.00
                                closingTime={props.closingTime}  //    Closing hour - 17.00
                                leaves={_.filter(props.leaves, (a) => { return a.permission === "Approved" })}
                                holidays={props.holidays}
                                appointments={_.filter(props.appointments, (a) => { return a.status === 'Active' })}
                                submitAppointment={props.submitAppointment}
                                businessTypeId={props.businessTypeId}
                                edit={false}
                                branchId={props?.selectedBranch?.branchId}
                                timeZone={props.timeZone}
                                isSubscribed={props.isSubscribed}
                                selectedBranch={props?.selectedBranch}
                                isHandleByRedux={true}
                            />
                        }
                        {(props.businessMode === "FacilityMode") && <AddAppointmentForFacilityModal
                            shopId={props.shopId}
                            services={props.services}
                            employees={props.employees}
                            openingHours={props.shopTimeArr}   // NEW
                            openingTime={props.openingTime}    // Opening hour - 9.00
                            closingTime={props.closingTime}  //    Closing hour - 17.00
                            leaves={_.filter(props.leaves, (a) => { return a.permission === "Approved" })}
                            holidays={props.holidays}
                            appointments={_.filter(props.appointments, (a) => { return (a.status === APPOINTMENT_STATUS.ACTIVE.code || a.status === APPOINTMENT_STATUS.PENDING_PAYMENT.code) })}
                            submitAppointment={props.submitAppointment}
                            businessTypeId={props.businessTypeId}
                            edit={false}
                            branchId={props?.selectedBranch?.branchId}
                            facilities={props.facilities}
                            timeOffs={props.timeOffs}
                            timeZone={props.timeZone}
                            isSubscribed={props.isSubscribed}
                            selectedBranch={props?.selectedBranch}
                            isHandleByRedux={true}
                        />
                        }
                    </div>
                    <button className="navbar-toggler btn-sm d-block d-md-none" type="button" onClick={() => { setToggle(!isToggle) }} >
                        {isToggle ? <span aria-hidden="true" style={{ fontSize: "35px", color:"black"}} >&times;</span> : <span className="navbar-toggler-icon" id="navbar-custom"></span>}
                    </button>
                </div>
                <ul style={{ width: "calc(100% - 150px)" }} className="d-none d-md-flex flex-row align-items-center navbar-nav px-3">
                    <li className="d-flex align-items-center" >
                        <h1 className="text-nav text-business-name mb-0" style={{ letterSpacing: "7px", fontSize: "23px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} >{props.businessName} </h1>
                    </li>
                    <li className="ml-auto" >
                        {props.businessMode === 'EmployeeMode' &&
                            <AddAppointmentModal
                                shopId={props.shopId}
                                services={props.services}
                                employees={props.employees}
                                openingHours={props.shopTimeArr}   // NEW
                                openingTime={props.openingTime}    // Opening hour - 9.00
                                closingTime={props.closingTime}  //    Closing hour - 17.00
                                leaves={_.filter(props.leaves, (a) => { return a.permission === "Approved" })}
                                holidays={props.holidays}
                                appointments={_.filter(props.appointments, (a) => { return a.status === 'Active' })}
                                submitAppointment={props.submitAppointment}
                                businessTypeId={props.businessTypeId}
                                edit={false}
                                branchId={props?.selectedBranch?.branchId}
                                timeZone={props.timeZone}
                                isSubscribed={props.isSubscribed}
                                selectedBranch={props?.selectedBranch}
                                isHandleByRedux={true}
                            />
                        }
                        {props.businessMode === 'FacilityMode' &&
                            <AddAppointmentForFacilityModal
                                shopId={props.shopId}
                                services={props.services}
                                employees={props.employees}
                                openingHours={props.shopTimeArr}   // NEW
                                openingTime={props.openingTime}    // Opening hour - 9.00
                                closingTime={props.closingTime}  //    Closing hour - 17.00
                                leaves={_.filter(props.leaves, (a) => { return a.permission === "Approved" })}
                                holidays={props.holidays}
                                appointments={_.filter(props.appointments, (a) => { return (a.status === APPOINTMENT_STATUS.ACTIVE.code || a.status === APPOINTMENT_STATUS.PENDING_PAYMENT.code) })}
                                submitAppointment={props.submitAppointment}
                                businessTypeId={props.businessTypeId}
                                edit={false}
                                branchId={props?.selectedBranch?.branchId}
                                facilities={props.facilities}
                                timeOffs={props.timeOffs}
                                timeZone={props.timeZone}
                                isSubscribed={props.isSubscribed}
                                selectedBranch={props?.selectedBranch}
                                isHandleByRedux={true}
                            />
                        }
                    </li>
                    <li>
                        {isSuperAdmin() &&
                            <UncontrolledDropdown className="an-profile-drop-down" >
                                <DropdownToggle className="d-flex align-items-center bg-transparent border-0" caret>
                                    <img className="rounded-circle border border-secondary p-1" src={props.logoUrl ? props.logoUrl : 'https://firebasestorage.googleapis.com/v0/b/smartbooking-dev.appspot.com/o/admin_profile%2Fimg_avatar.png?alt=media&token=dde886ec-8143-48f2-92c3-259090662b87'} alt="shop" title="Shop profile" width="30" height="30" />
                                    <span className="text-nav ml-2 small mr-2" >{props.selectedBranch?.branchName}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {props.branches.map((branch, idx) => {
                                        return (
                                            <DropdownItem
                                                key={idx}
                                                className="d-flex align-items-baseline px-2"
                                                onClick={() => {
                                                    props.selectBranch(branch).then(() => {
                                                        history.push({ pathname: '/dashboard', isChangeBranch: true })
                                                        //page refresh for immediate update
                                                        window.location.reload();
                                                    })
                                                }}>
                                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} >{branch.branchName}</div>
                                            </DropdownItem>
                                        )
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        }
                    </li>
                    {!employee.loading &&
                        <li className="d-flex align-items-center" >
                            <UncontrolledDropdown className="an-profile-drop-down" >
                                <DropdownToggle className="d-flex align-items-center bg-transparent border-0" caret>
                                    <img className="rounded-circle border border-secondary p-1"
                                        src={isSuperAdmin() ? (
                                            props.imageUrl ? props.imageUrl :
                                                'https://firebasestorage.googleapis.com/v0/b/smartbooking-dev.appspot.com/o/admin_profile%2Fimg_avatar.png?alt=media&token=dde886ec-8143-48f2-92c3-259090662b87'
                                        )
                                            :
                                            (
                                                employee.value ? (
                                                    employee.value.imageUrl ? employee.value.imageUrl :
                                                        'https://firebasestorage.googleapis.com/v0/b/smartbooking-dev.appspot.com/o/admin_profile%2Fimg_avatar.png?alt=media&token=dde886ec-8143-48f2-92c3-259090662b87'
                                                )
                                                    : null
                                            )
                                        }
                                        alt="shop" title="Shop profile" width="30" height="30" />
                                    <span className="text-nav ml-2 small mr-2" >
                                        {isSuperAdmin() ?
                                            props.ownerName
                                            : (employee.value ? `${employee.value.employeeFirstName} ${employee.value.employeeLastName}` : null)
                                        }
                                        <br />
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <NavLink onClick={() => {
                                        setToggle(!isToggle)
                                    }} to="/settings" className="d-flex align-items-baseline px-2" >
                                        <SettingsIcon style={{ width: "20px", height: "20px", fill: "#2196F3", paddingTop: "5px" }} />
                                        <div className="ml-1 d-flex flex-column" >
                                            <div className="font-weight-normal text-body" >Settings</div>
                                            <span className="text-muted small font-weight-bold" >Edit your profile</span>
                                        </div>
                                    </NavLink>
                                    <DropdownItem divider />
                                    <NavLink onClick={() => {
                                        setToggle(!isToggle)
                                        props.signOut(() => { })
                                    }} to="/login" className="d-flex align-items-center px-2">
                                        <LogoutIcon style={{ width: "20px", height: "20px", fill: "#E91E63", paddingTop: "5px" }} />
                                        <span className="ml-1  text-body" >Log Out</span>
                                    </NavLink>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </li>
                    }
                </ul>
            </nav>
            <div className="container-fluid px-0">
                <nav className={` ${isToggle ? "" : "sidebar-hidden"} d-md-block sidebar position-fixed`}>
                    <div className={`${isToggle ? "sidebar-sticky-animi" : "sidebar-sticky-animi-revise"} sidebar-sticky mt-0 mt-md-0`} style={{ scrollbarWidth: "none" }}>
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <NavLink activeClassName="an-active-nav" className="nav-link text-white" onClick={() => {
                                    setToggle(!isToggle)
                                }} to="/dashboard" tag={Link}  >
                                    <div className="d-felx side-menu__icon" ><DashboardIcon /></div>
                                    <div className="side-menu__title" >DashBoard</div>
                                </NavLink>
                            </li>
                            {props.businessMode === 'EmployeeMode' &&
                                <li className="nav-item">
                                    <NavLink activeClassName="an-active-nav" className="nav-link text-dark" onClick={() => setToggle(!isToggle)} to="/appointments" tag={Link}  >
                                        <div className="d-felx side-menu__icon" > <AppointmentsIcon /></div>
                                        <div className="side-menu__title" >Appointments</div>
                                    </NavLink>
                                </li>
                            }
                            {props.businessMode === 'FacilityMode' &&
                                <li className="nav-item">
                                    <NavLink activeClassName="an-active-nav" className="nav-link text-dark" onClick={() => setToggle(!isToggle)} to="/facilityAppointments" tag={Link}  >
                                        <div className="d-felx side-menu__icon" > <AppointmentsIcon /></div>
                                        <div className="side-menu__title" >Appointments</div>
                                    </NavLink>
                                </li>
                            }
                            <li className="nav-item">
                                <NavLink activeClassName="an-active-nav" className="nav-link text-white" onClick={() => setToggle(!isToggle)} to="/services" tag={Link}  >
                                    <div className="d-felx side-menu__icon" > {checkServicesIcon()}</div>
                                    <div className="side-menu__title" >{businessTypes(props.businessTypeId, 'services')}</div>
                                </NavLink>
                            </li>
                            {props.businessMode === 'EmployeeMode' &&
                                <li className="nav-item">
                                    <NavLink activeClassName="an-active-nav" className="nav-link text-white" onClick={() => setToggle(!isToggle)} to="/staff" tag={Link}  >
                                        <div className="d-felx side-menu__icon" > {checkStaffIcon()}</div>
                                        <div className="side-menu__title" >{businessTypes(props.businessTypeId, 'business_category_pluarl')}</div>
                                    </NavLink>
                                </li>
                            }
                            {props.businessMode === 'FacilityMode' &&
                                <li className="nav-item">
                                    <NavLink activeClassName="an-active-nav" className="nav-link text-white" onClick={() => setToggle(!isToggle)} to="/facilities" tag={Link}  >
                                        <div className="d-felx side-menu__icon" > {checkStaffIcon('facilityIcon')}</div>
                                        <div className="side-menu__title" >Facilities</div>
                                    </NavLink>
                                </li>
                            }
                            <li className="nav-item">
                                <NavLink activeClassName="an-active-nav" className="nav-link text-white" onClick={() => setToggle(!isToggle)} to="/customers" tag={Link}  >
                                    <div className="d-felx side-menu__icon" > <CustomerIcon /></div>
                                    <div className="side-menu__title" >Customers</div>
                                </NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink activeClassName="an-active-nav" className="nav-link text-white" onClick={() => {
                                    setToggle(!isToggle)
                                }} to="/covid" tag={Link}  >
                                    <div className="d-felx side-menu__icon" ><CovidIcon /></div>
                                    <div className="side-menu__title" >Covid 19 Tracing</div>
                                </NavLink>
                            </li> */}
                            <li className="nav-item">
                                <NavLink activeClassName="an-active-nav" className="nav-link text-white" onClick={() => {
                                    setToggle(!isToggle)
                                }} to="/settings" tag={Link}  >
                                    <div className="d-felx side-menu__icon" ><SettingsIcon /></div>
                                    <div className="side-menu__title" >Settings</div>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="an-active-nav" className="nav-link text-white" onClick={() => {
                                    setToggle(!isToggle)
                                }} to="/admin" tag={Link}  >
                                    <div className="d-felx side-menu__icon" ><AdminIcon /></div>
                                    <div className="side-menu__title" >Admin</div>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink activeClassName="an-active-nav" className="nav-link text-white" onClick={() => {
                                    setToggle(!isToggle)
                                }} to="/analytics" tag={Link}  >
                                    <div className="d-felx side-menu__icon" ><AnalyticsIcon /></div>
                                    <div className="side-menu__title" >Analytics</div>
                                </NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink activeClassName="an-active-nav" className="nav-link text-white" onClick={() => {
                                    setToggle(!isToggle)
                                }} to="/support" tag={Link}  >
                                    <div className="d-felx side-menu__icon" ><SupportIcon /></div>
                                    <div className="side-menu__title" >Support</div>
                                </NavLink>
                            </li> */}
                            <li className="d-md-none nav-item">
                                <NavLink activeClassName="an-active-nav" className="nav-link text-white" onClick={() => {
                                    setToggle(!isToggle)
                                    props.signOut(() => { })
                                }} to="/login" tag={Link}  >
                                    <div className="d-felx side-menu__icon" > <LogoutIcon /></div>
                                    <div className="side-menu__title" >Sign Out</div>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </nav>

                <main role="main" >
                    {props.main}
                </main>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        openingTime: state.shop.openingTime,
        closingTime: state.shop.closingTime,
        openingHours: state.shop.openingHours,
        employees: state.shop.employees,
        totalEmployees: state.shop.totalEmployees,
        services: state.shop.totalServices,
        leaves: state.shop.leaves,
        holidays: state.shop.holidays,
        appointments: state.shop.appointments,
        businessTypeId: state.shop.businessTypeId,
        timeZone: state.shop.timeZone,

        userName: state.user.name,
        imageUrl: state.shop.imageUrl,
        logoUrl: state.shop.logoUrl,
        shopName: state.shop.name,
        businessName: state.shop.businessName,
        ownerName: state.shop.ownerName,
        branches: state.shop.branches,
        selectedBranch: !_.isEmpty(state.shop.selectedBranch) ? state.shop.selectedBranch : state.shop.branches[0],
        shopId: state.shop.shopId,
        shopTimeArr: state.shop.shopTimeArr,
        businessMode: state.shop.businessMode,
        isSubscribed: state.shop.isSubscribed,

        facilities: state.shop.totalFacilities,
        timeOffs: state.shop.timeOffs,
    };
};
export default connect(mapStateToProps, { submitAppointment, signOut, selectBranch })(SideBar);
